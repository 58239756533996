<div class="tab-menu">
    <ng-container *ngFor="let tab of tabs">
        <span 
            class="tabs" 
            [class.selected]="tab.isActive" 
            [class.disabled]="tab.isDisabled" 
            (click)="!tab.isDisabled && selectTab(tab)" 
            [attr.aria-disabled]="tab.isDisabled ? 'true' : 'false'">
            {{ tab.label | driveLabelPipe }}
        </span>
    </ng-container>
</div>
