import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RoundsService {

  constructor(private http: HttpService) { }

  async fetchForInterviewProcess(payload: any) {
    const fetchForInterviewProcess =
      await this.http.post(
        '/drives/fetchForInterviewProcess',
        payload
      );
    return fetchForInterviewProcess;
  }

  async uploadOfferOrConfirmation(payload: any) {
    const uploadOfferOrConfirmation =
      await this.http.post(
        '/drives/uploadOfferOrConfirmation',
        payload
      );
    return uploadOfferOrConfirmation;
  }

  async getCountOfEveryStages(payload: any) {
    const getCountOfEveryStages =
      await this.http.post(
        '/drives/getCountOfEveryStages',
        payload
      );
    return getCountOfEveryStages;
  }

  async manuallyAddRemoveStudents(payload: any) {
    const manuallyAddRemoveStudents =
      await this.http.post(
        '/drives/manuallyAddRemoveStudents',
        payload
      );
    return manuallyAddRemoveStudents;
  }

  async removeOfferOrConfirmation(payload: any) {
    const removeOfferOrConfirmation =
      await this.http.post(
        '/drives/removeOfferOrConfirmation',
        payload
      );
    return removeOfferOrConfirmation;
  }

  async drivesStudentDataDownload(payload: any) {
    const drivesStudentDataDownload =
      await this.http.post(
        '/drives/drivesStudentDataDownload',
        payload
      );
    return drivesStudentDataDownload;
  };

  async driveMoveToRounds(payload: any) {
    const driveMoveToRounds =
      await this.http.post(
        '/drives/driveMoveToRounds',
        payload
      );
    return driveMoveToRounds;
  };

  async driveStatusChange(payload: any) {
    const driveStatusChange =
      await this.http.post(
        '/drives/driveStatusChange',
        payload
      );
    return driveStatusChange;
  }

  async getDriveEvents(payload: any) {
    return await this.http.post('/drive-planner/getDriveEvents', payload);
  }

  async validatePlacedEmails(payload: any) {
    try {
      const response = await this.http.post('/drives/validateEmails', payload);
      return response;
    } catch (error) {
      console.error('Error validating placed emails:', error);
      throw new Error('Failed to validate placed emails. Please try again later.');
    }
  }  
}
