import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HandleErrorComponent } from './handle-error.component';


@NgModule({
  declarations: [
    HandleErrorComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [HandleErrorComponent]
})
export class HandleErrorModule { }
