import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root',
})
export class MessageInsightsService {
  options() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '',
      Origin: window.location.origin,
    });
    return { headers };
  }

  constructor(private http: HttpService) {}

  async getCampaignStatus(payload: any) {
    let result: any = await this.http.post(`/message-insights/campaignStatus`, payload);
    return result.data;
  }
  
  async getAllCampaign(payload: any) {
    return await this.http.post(`/message-insights/getAllCampaigns`, payload);
  }

  async downloadAttachment(payload: any) {
    return await this.http.post(`/message-insights/downloadAttachment`, payload);
  }
}
