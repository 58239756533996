import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { PipeModule } from '@pipes';

@NgModule({
  declarations: [
    ButtonComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [ButtonComponent]
})
export class ButtonModule { }
