import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DriveDetailsRoutingModule } from './drive-details-routing.module';
import { DriveDetailsComponent } from './drive-details.component';

import { SplitterModule } from 'primeng/splitter';
import { TableComponentModule, ButtonModule, TabModule, HandleErrorModule } from '@shared';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EligibilityCriteriaModule } from '../eligibility-criteria/eligibility-criteria.module';
import { RoundsModule } from '../rounds/rounds.module';
import { DriveEmailTemplateModule } from '../drive-email-template/drive-email-template.module';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { PipeModule } from '@pipes';
import { EditorModule } from 'primeng/editor';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DriveRoundCreationModule } from '../drive-round-creation/drive-round-creation.module';

@NgModule({
  declarations: [
    DriveDetailsComponent,
  ],
  imports: [
    PipeModule,
    CommonModule,
    DriveDetailsRoutingModule,
    FormsModule,
    SplitterModule,
    TableComponentModule,
    ButtonModule,
    TabModule,
    DropdownModule,
    TooltipModule,
    SidebarModule,
    AccordionModule,
    ReactiveFormsModule,
    InputTextModule,
    ChipsModule,
    CheckboxModule,
    ToastModule,
    TableModule,
    MultiSelectModule,
    ConfirmDialogModule,
    CalendarModule,
    ProgressSpinnerModule,
    EligibilityCriteriaModule,
    RoundsModule,
    DriveEmailTemplateModule,
    HandleErrorModule,
    DialogModule,
    DividerModule,
    EditorModule,
    NgxDropzoneModule,
    InputSwitchModule,
    DriveRoundCreationModule
  ],
  exports: [DriveDetailsComponent]
})
export class DriveDetailsModule { }
