import { Directive, Optional, Self } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appStandalone]'
})
export class StandaloneDirective {

  constructor(@Optional() @Self() private ngModel: NgModel) {
    if (this.ngModel) {
      this.ngModel.options = { ...this.ngModel.options, standalone: true };
    }
  }
}
