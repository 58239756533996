//export every enum file here
export * from '../enums/table.enum';
export * from '../enums/global.enum';
export * from '../enums/programme-offered.enum';
export * from '../enums/drive.enum';
export * from '../enums/students.enum';
export * from '../enums/eligibility.enum';
export * from '../enums/rounds.enum';
export * from '../enums/audit.enum';
export * from '../enums/settings.enum';
export * from '../enums/batch.enum';
export * from '../enums/growl.enum';
export * from '../enums/email-template.enum';
export * from '../enums/rounds-dates.enum';
export * from '../enums/calender.enum';