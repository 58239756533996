import { Injectable } from '@angular/core';
// import { Messaging, getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, onMessage } from 'firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  userData: any;
  deviceToken: any;
  accountData: any;
  notificationData: any[] = [];
  panelData: any[] = [];
  notificationCount: number = 0
  messaging: Messaging;

  constructor(private httpService: HttpService, private globalService: GlobalService) {
    this.setLocalStorage();

    const firebaseApp = initializeApp(environment.firebaseConfig);
    this.messaging = getMessaging(firebaseApp);
  };

  setLocalStorage() {
    this.userData = localStorage.getItem('user_details');
    this.deviceToken = localStorage.getItem('deviceToken');
    this.accountData =  localStorage.getItem('account_details');
    this.userData = JSON.parse(this.userData);
    this.accountData = JSON.parse(this.accountData);
  }

  async requestNotificationsPermissions () {
    this.setLocalStorage();
    const permission = await Notification.requestPermission();
    console.log('Requesting notifications permission...', permission);

    if (permission === 'granted') {
      await this.getToken();
    } else {
      console.log('Unable to get permission to notify.');
    }
  };

  async getToken() {
    try {
      const currentToken = await getToken(this.messaging, { vapidKey: environment.vapidKey });
      if (currentToken) {
        if (currentToken !== this.deviceToken) {
          await this.setToken(currentToken);
        }
        this.listenForNotifications();
      }
    } catch (error) {
      console.error('An error occurred while retrieving token.', error);
    }
  }
  

  async setToken(token: string) {
    const payload = {
      user_id: this.userData.user_id,
      token
    };

    localStorage.setItem('deviceToken', token);
    await this.httpService.post('/notifications/setToken', payload);
  }

  async fetchNotifications() {

    const notify_payload = {
      group_account_id: this.accountData.group_account_id,
      user_type: 'Group Admin',
    };

    let response = await this.httpService.post('/notifications/getNotifications', notify_payload);
    this.panelData = response.data.data;
    this.notificationData = this.panelData.map(el => el);
    this.notificationCount = response.data.unread;
  };

  listenForNotifications(): void {
    console.log("stared listening")
    onMessage(this.messaging, (notification) => {
        console.log("Message received ", notification);
        if(!notification.data) return;
        this.panelData.unshift(notification.data);
        this.notificationData.unshift(notification.data);
        this.notificationCount += 1;

        this.globalService.showNotification(`${this.notificationCount} new notification`, notification.data['title']);
    });
  };

  async deleteNotification(item: any) {
    this.notificationData = this.notificationData.filter((each: any) => {
        if(each.id === item.id) {
          if(each.status == 'unread') {
            this.notificationCount -= 1;
          }
          return false
        }
        return true
    });

    this.panelData = this.panelData.filter((each) => each.id != item.id);

    await this.httpService.post('/notifications/delete', item);
  }

  async unreadOnly(showUnRead: boolean) {
    if(showUnRead) {
      this.notificationData = this.panelData.map(el => el);
    }
    else {
      this.notificationData = this.notificationData.filter((each) => each.status == 'unread')
    }
  }
}
