import { Component, EventEmitter, Input, Output } from '@angular/core';
import { iconsList } from '@models';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent {
  @Input() tabs: iconsList[] = [];
  @Output() tabChange: EventEmitter<iconsList> = new EventEmitter();

  selectTab(selectedTab: iconsList) {
    this.tabs.forEach(tab => tab.isActive = tab === selectedTab);
    this.tabChange.emit(selectedTab);
  }
}
