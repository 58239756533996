import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { CookieService } from 'ngx-cookie-service';
import * as XLSX from 'xlsx';

type getSignedURLPayload = {
  bucketName: string,
  fileName: string,
}

type getSignedUploadURLPayload = {
  bucketName: string,
  fileName: string,
  type?: string,
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  bucketName: string = '';
  public defaultDateFormat: string = 'dd-MMM-yyyy';
  public defaultDateFormatWithTime: string = 'dd-MMM-yyyy HH:mm';
  public defaultDateFormatWithTimeSeconds: string = 'dd-MMM-yyyy HH:mm:ss';
  public emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  public regNoPattern: any = /^(?=.*[a-zA-Z0-9])/;
  public mobilePattern = /^\d{10}$/;
  public studentBucket: string = '';
  public publicBucket: string = '';
  public prefix = `https://storage.googleapis.com`;
  public driveLabelSubject: BehaviorSubject<any> = new BehaviorSubject<any>({
    labeling: {
      categoty_label: "Drive Category",
      drive_label: "Drives",
      opt_in_label: "Opt-In",
      opt_out_label: "Opt-Out",
      salary_label: "Salary"
    }
  });
  public driveLabel = this.driveLabelSubject.asObservable();
  public accountDetailSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public groupAccountImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public imageUpdate = this.groupAccountImage.asObservable();
  public accountDetail = this.accountDetailSubject.asObservable();
  public groupAccURL: string = '';
  private isRefreshing = false;
  private refreshTokenSubject: Promise<string | null> | null = null;

  validToken: BehaviorSubject<any> = new BehaviorSubject<any>({});
  notification: Subject<{ message: string, title: string }> = new Subject<{message: string, title: string }>();
  constructor(public http: HttpService, public cookieService: CookieService) {
    let nodeEnv = environment.NODE_ENV;
    if(nodeEnv === 'production') {
      this.bucketName = 'pat-prod';
      this.studentBucket = 'pat-student-prod';
      this.publicBucket = 'pat-public-prod'
    }else{
      this.bucketName = 'pat-dev';
      this.studentBucket = 'pat-student';
      this.publicBucket = 'pat-public'
    }

    const group_account_id = JSON.parse(localStorage.getItem('account_details') ?? '{}').group_account_id;
    this.groupAccURL = `${this.prefix}/${this.publicBucket}/logo/${group_account_id}`;
  }

  getdefaultProfilePic() {
    return {
      default: `assets/default.svg`,
      male: `assets/Male.jpg`,
      female: `assets/Female.jpg`
    }
  }

  showNotification(message: string, title: string) {
    if(!message || !title) return;

    this.notification.next({
      message, 
      title
    })
  }

  tokenValidationMessage(message: any) {
    this.validToken.next(message)
  }

  getLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  };

  removeKeys() {
    let arr = ['account_details']
    for (let key in localStorage) {
      if (!
        arr.includes(key)) {
        this.removeLocalStorage(key)
      }
      // If need to add condition for not to delete particular key mention here
    }
  }

  getLabels(driveLabel: any) {
    const label_singular = driveLabel.drive_label === 'Jobs' ? 'Job' : 'Drive';
    const optin_past = driveLabel.opt_in_label === 'Opt-In' ? 'Opted-In' : 'Applied';
    const optout_past = driveLabel.opt_out_label === 'Opt-Out' ? 'Opted-Out' : 'Declined';
  
    return {
      label_singular,
      optin_past,
      optout_past
    };
  }

  formatFields(data: any)
  {
    let academic_info: any = {};
    let additional_info: any = {};
    let basic_info: any = {};

    data.academicInfo.forEach((info: any)=>
    {
      academic_info[info.attribute_name] = info.attribute_label ? info.attribute_label : info.attribute_name;
    })
    data.additionalInfo.forEach((info: any)=>
    {
      additional_info[info.attribute_name] = info.attribute_label ? info.attribute_label : info.attribute_name;
    })
    data.basicInfo.forEach((info: any)=>
    {
      basic_info[info.attribute_name] = info.attribute_label ? info.attribute_label : info.attribute_name;
    })

    return {academic_info, basic_info, additional_info};
  }
  
  setAccountDetails(data: any) {
    localStorage.setItem('account_metadata', JSON.stringify(data));
    this.driveLabelSubject.next(data);
  }

  setGroupAccountImage(image: string) {
    this.groupAccountImage.next(image);
    this.groupAccURL = image;
  }

  setgrpAccountImage(group_account_id: string) {
    this.groupAccURL = `${this.prefix}/${this.publicBucket}/logo/${group_account_id}?${new Date().getTime()}`;
  };

  setCookie(key: string, value: string, domain = environment.domain) {
    this.cookieService.set(key, value, this.getCookieConfig(domain));
  };

  getCookie(key: string) {
    return this.cookieService.get(key);
  };

  deleteCookie(key: string, domain = environment.domain) {
    this.cookieService.delete(key, '/', domain);
  };

  clearCookies() {
    this.deleteCookie('token');
    this.deleteCookie('user_details');
    this.deleteCookie('account_details');
    this.deleteCookie('refresh_token');
  };

  getCookieConfig(domain: string) {
    return {
      domain,
      path: '/',
      secure: this.isProd(), 
      sameSite: environment.sameSite as 'Lax' | 'None' | 'Strict'
    }
  };

  isProd() {
    return environment.protocol === 'https';
  };

  redirectUrl(path: string, subPath = '') {
    if(this.isProd()) {
      return `${environment.protocol}://${path}.${environment.domainType}/${subPath}`;
    }
    return `${environment.protocol}://${path}.${environment.domainType}:4000/${subPath}`;
  };

  redirectToLoginPage(): any {
    if(this.isProd()) {
      return window.location.href = `${environment.protocol}://${environment.loginPage}`;
    }
    return window.location.href = `${environment.protocol}://${environment.loginPage}:4000`;
  };

  validateDomain() {
    const subdomain = this.getCookie('subdomain');
    const currentDomain = window.location.hostname;

    return `${subdomain}.${environment.domainType}` === currentDomain;
  };

  resetLogin() {
    const subdomain = this.getCookie('subdomain');
    this.removeKeys();
    this.deleteCookie('refresh_token', subdomain + '.' + environment.domainType);
    this.deleteCookie('subdomain');
    this.clearCookies();
    this.redirectToLoginPage();
  };

  getRefreshToken() {
    return this.getCookie('refresh_token');
  };

  async refreshToken() {
    const refreshToken = this.getRefreshToken();
    // If no refresh token, reset login
    if(!refreshToken) {
      this.resetLogin();
    };

    // If refreshing token, return the promise
    if(this.isRefreshing) {
      return this.refreshTokenSubject;
    }

    this.isRefreshing = true;
    this.refreshTokenSubject = new Promise<string | null>(async (resolve, reject) => {
      try {
        const response = await this.http.post('/refreshToken', { token: refreshToken });
        if(response.success && response.data) {
          const { token, refresh_token } = response.data;
          this.setTokens(token, refresh_token);
          return resolve(token);
        }
        return resolve(null);
      }
      catch(e) {
        resolve(null);
      }
      finally {
        this.isRefreshing = false;
      }
    });

    return this.refreshTokenSubject;
  };

  setTokens(accessToken: string, refreshToken: string) {
    const subdomain = this.getCookie('subdomain');
    this.setLocalStorage('token', JSON.stringify(accessToken));
    this.setCookie('refresh_token', refreshToken, subdomain + '.' + environment.domainType);
  };

  async fetchAccounts(group_account_id: string) {
    const fetchProgrammeDetails =
      await this.http.get(
        `/programmeMapping/fetchAccounts/${group_account_id}`,
      );
    return fetchProgrammeDetails;
  }

  async fetchEngagement(payload: any) {
    const fetchEngagement =
      await this.http.post('/engagement-metrics/fetchMetrics', payload);
    return fetchEngagement;
  }

  async fetchDepartment(group_account_id: string) {
    const fetchProgrammeDetails =
      await this.http.get(
        `/programmeMapping/fetchDepartment/${group_account_id}`,
      );
    return fetchProgrammeDetails;
  }


  async getAllCustomTemplate(body: any) {
    const response =
    await this.http.post(
      '/emailTemplate/getAllCustomTemplate',
      body
    );
    return response;
  }

  async validateEmail(email: any){
    const response = await this.http.post('/validateEmail', email);
    return response;
  }

  async fetchCampusDetails(group_account_id: string) {
    const fetchCampusDetails =
      await this.http.get(
        `/student/fetchCampusDetails/${group_account_id}`
      );
    return fetchCampusDetails;
  }

  async fetchDepartmentsData(campusList: any) {
    let departments: any = [], accounts: any = [];
    for (let each of campusList) {
      accounts.push(each.account_id);
      let fetchDepartmentDegreeSpecialization = await this.http.get(`/student/fetchDepartmentDegreeSpecialization/${each.account_id}`);
      for (let every of fetchDepartmentDegreeSpecialization.data) {
        every.campus = each.full_name;
      }
      departments = [...departments, ...fetchDepartmentDegreeSpecialization.data];
    }
    return {
      accounts: accounts,
      departments: departments
    };
  }
  async fetchAllTemplate(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/getGroupAccountTemplate',
        body
      );
    return addCategory;
  }

  async getDriveTypes() {
    let types = await this.http.get('/drives/types');
    return types.data;
  }

  async fetchAccountMetadata() {
    let account_details = this.getLocalStorage('account_details');
    const accountDetails = account_details ? JSON.parse(account_details || '') : {};
    if (!accountDetails) return;
    try {
      const { data, success } = await this.fetchMetaData(accountDetails)
      if (!success || !data) return;
      this.setAccountDetails(data);
    } catch (error) {
      console.error('Error in fetchMetaData', error)
    }
  }

  async fetchMetaData(data: any) {
    try {
      const { group_account_id } = data
      const res = await this.http.get(`/settings/getAccountMetaData/${group_account_id}`)
      return res
    } catch (error) {
      throw new Error('Error in fetchMetaData')
    }
  }

  async getCompanies() {
    let companies = await this.http.get('/drives/companies');
    return companies.data;
  }

  async getCompanyCategories() {
    let company_category = await this.http.get('/drives/companyCategory');
    return company_category.data;
  }

  async getOfferType() {
    let offer_type = await this.http.get('/drives/offerType');
    return offer_type.data;
  }

  async setTableDefaults(payload: any) {
    return await this.http.post('/user/setTableDefaults', payload);
  }

  async fetchFieldLabels(group_account_id: string) {
    const fetchFields = await this.http.get(`/studentFields/fetchFieldLabels/${group_account_id}`);
    return fetchFields;
  }

  async getTableDefaults(payload: any) {
    return await this.http.post('/user/getTableDefaults', payload);
  }

  async fetchEmailTemplates(group_account_id: string) {
    return await this.http.get(`/emailTemplate/getEmailTemplates/${group_account_id}`)
  }

  async fetchFields(group_account_id: string) {
    const fetchFields =
      await this.http.get(
        `/studentFields/fetchFields/${group_account_id}`,
      );
    return fetchFields;
  }

  async fetchMappedFieldsBulk(body: any) {
    const fetchFields =
      await this.http.post(
        `/studentFields/fetchMappedFieldsBulk`,
        body
      );
    return fetchFields;
  }

  async fetchMappedFields(body: any) {
    const fetchFields =
      await this.http.post(
        `/studentFields/fetchMappedFields`,
        body
      );
    return fetchFields;
  }

  cloneDeep(obj: any) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    let clone: any = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      clone[key] = this.cloneDeep(obj[key]);
    }
    
    return clone;
  }

  loadScript(
    element: string,
    url: string,
    type: string,
    id: string,
    child?: any[],
    defer?: any,
    onload?: any,
    onerror?: any
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (document.getElementById(id)) {
        resolve(true);
      } else {
        const node: any = document.createElement(element);
        if (element === 'link') {
          node.href = url;
          node.rel = 'stylesheet';
        } else if (element === 'script') {
          node.src = url;
        }
  
        if (defer) {
          node.defer = defer;
        }
  
        if (onload) {
          node.onload = () => {
            onload();
            resolve(true);
          };
        } else {
          node.onload = () => {
            resolve(true);
          };
        }
  
        if (onerror) {
          node.onerror = () => {
            onerror();
            reject(new Error("error in loading script"));
          };
        } else {
          node.onerror = () => {
            reject(new Error("error in loading script"));
          };
        }
        node.type = type;
        node.id = id;
        document.getElementsByTagName('head')[0].appendChild(node);
        node.onload = () => {
          if (child?.length) {
            const promiseArray: any[] = [];
            child.forEach((script: any) => {
              promiseArray.push(
                this.loadScript(
                  script.element,
                  script.url,
                  script.type,
                  script.rel,
                  script.id,
                  script.child
                )
              );
            });
            Promise.all(promiseArray).then(() => {
              resolve(true);
            }).catch(reject);
          } else {
            resolve(true);
          }
        };
        node.onerror = () => {
          reject(new Error("error in loading script"));
        };
      }
    });
  }

  async getSignedURL(payload: getSignedURLPayload){
    return await this.http.post('/getSignedUrl', payload);
  }

  async getSignedUploadUrl(payload: getSignedUploadURLPayload){
    return await this.http.post('/getSignedUploadUrl', payload);
  }

  async sendEmail(payload: any) {
    return await this.http.post('/student/sendEmail', payload);
  }

  async sendNotification(payload: any) {
    return await this.http.post('/student/sendNotification', payload);
  }

  async sendWhatsappNotification(payload: any){
    return await this.http.post('/student/sendWhatsappNotification', payload);
  }

  async getFiles(url: string): Promise<any>{
    return new Promise((resolve, reject) => {
      this.http.getFile(url).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  async uploadFile(url: string, file: any): Promise<any>{
    return new Promise((resolve, reject) => {
      this.http.uploadFile(url, file).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  removeScript() {
    let script = document.getElementById("examlyck")
    if (script) {
      script.remove();
    }
  }

  async getSignedUrl(payload: { bucketName: string, fileName: string }) {
    const result = await this.http.post('/getSignedUrl', payload);
    return result;
  }

  setTitle(title: string) {
    document.title = title;
  }

  getIconClass(filename: string): string {
    const extension = filename.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            return 'pi-image';
        case 'zip':
            return 'pi-folder';
        case 'doc':
        case 'docx':
            return 'pi-file-word';
        case 'pdf':
            return 'pi-file-pdf';
        case 'xls':
        case 'xlsx':
            return 'pi-file-excel';
        default:
            return 'pi-file';
    }
  }

  formatDate(date: string, formatType: 'date' | 'dateTime' | 'dateTimeSeconds'): string {
    switch (formatType) {
        case 'date':
            return this.format(date, this.defaultDateFormat);
        case 'dateTime':
            return this.format(date, this.defaultDateFormatWithTime);
        case 'dateTimeSeconds':
            return this.format(date, this.defaultDateFormatWithTimeSeconds);
        default:
            throw new Error('Invalid format type');
    }
  };

  format(date: string, format: string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(date);
    
    const day = ('0' + d.getDate()).slice(-2);
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    const seconds = ('0' + d.getSeconds()).slice(-2);
    
    let formattedDate = format;
    formattedDate = formattedDate.replace('dd', day);
    formattedDate = formattedDate.replace('MMM', month);
    formattedDate = formattedDate.replace('yyyy', `${year}`);
    formattedDate = formattedDate.replace('HH', hours);
    formattedDate = formattedDate.replace('mm', minutes);
    formattedDate = formattedDate.replace('ss', seconds);
    
    return formattedDate;
  };

  public keyMapping(key: string, data: any) {
    if(!data) return '-';
    if(Array.isArray(data[key])) return data[key].join(', ');
    return (data[key] == 0 || data[key]) ? data[key] : '-';
  };

  saveFile(data: Blob, fileName: string): void {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  downloadFile(downloadData: any, type: 'excel' | 'csv', fileName: string) {
    const worksheet = XLSX.utils.json_to_sheet(downloadData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array', });
    this.saveAsExcelFile(excelBuffer, fileName, type);
  }

  saveAsExcelFile(buffer: any, fileName: string, type: 'excel' | 'csv'): void {
    let FILE_TYPE = type === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], { type: FILE_TYPE, });
    this.saveFile(data, fileName + FILE_EXTENSION);
  }
}
