<div class="sidebar" [ngStyle]="{'background-image': 'url(' + sideBarPattern + ')'}">
    <div class="img-cnt">
      <img class="sidebar-sourceImg" (error)="imageLoadError($event)" [src]="neoPatIcon" alt="" />
    </div>
    <div class="sidebar-icons">
      <ng-container *ngFor="let icons of iconsList">
        <button class="icon-fun {{icons.isActive ? 'active' : ''}}" [pTooltip]="icons.toolTip" tooltipPosition="left" (click)="navClick(icons)">
            <img [src]="icons.value" alt="">
        </button>
      </ng-container>
    </div>
  </div>