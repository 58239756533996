export enum Rounds{
    DELETESTUDENTS = 'Remove Students',
    ADDSTUDENTS = 'Add Students',
    ADD = 'Add',
    REMOVE = 'Remove',
    ADDSTUDENTMANUALLY = 'Add Students Manually',
    REMOVESTUDENTMANUALLY = 'Remove Students Manually',
    EMAILIDS = 'Email IDs',
    ADDUSING = 'Add Using',
    ADDVIA = 'Add Via',
    PROFILEDESIGNATION = 'Add Profile/Designation',
    SELECTPROFILEDESIG = 'Select Profile/Designation'
}

export const RBtnProperties = {
    cancel: {
        btnName: 'Cancel',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-secondary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    upload: {
        btnName: 'Upload',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    }
}

export const addRemoveStudents = [
    { label: 'Add Students', value: 'addStudents' },
    { label: 'Remove Students', value: 'removeStudents' },
]