import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { DateRangePickerComponent } from './date-range-picker.component';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [
    DateRangePickerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    ButtonModule,
    CardModule
  ],
  exports: [
    DateRangePickerComponent
  ]
})
export class DateRangePickerModule { }
