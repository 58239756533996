import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentageSymbolPipe } from './percentage-symbol.pipe';
import { ExtractFilenamePipe } from './extractfilename.pipe';
import { DriveLabelPipe } from './driveLabel.pipe';
import { FieldLabelPipe } from './fieldLabel.Pipe';
import { DisplayTextPipe } from './displayValue.pipe';

@NgModule({
  declarations: [PercentageSymbolPipe, ExtractFilenamePipe, DriveLabelPipe, FieldLabelPipe, DisplayTextPipe],
  imports: [
    CommonModule
  ],
  exports: [PercentageSymbolPipe, ExtractFilenamePipe, DriveLabelPipe, FieldLabelPipe, DisplayTextPipe]
})
export class PipeModule { }
