import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
    name: 'fieldLabelPipe'
})
export class FieldLabelPipe implements PipeTransform {
    private fieldLabelSubject: any = {};
    basic_info: any = {};
    academic_info: any = {};
    additional_info: any = {};

    constructor(public globalService: GlobalService) {
        const { basic_info, academic_info, additional_info } = this.getAccountDetails() || {};
        this.basic_info = basic_info || {};
        this.academic_info = academic_info || {};
        this.additional_info = additional_info || {};
    }

    transform(value: string, limit: number = 100): any {
        if (!this.fieldLabelSubject || typeof value !== 'string') {
            return value;
        }
    
        let labelText: string;
        if (this.basic_info.hasOwnProperty(value)) {
            labelText = this.basic_info[value];
        } else if (this.academic_info.hasOwnProperty(value)) {
            labelText = this.academic_info[value];
        } else if (this.additional_info.hasOwnProperty(value)) {
            labelText = this.additional_info[value];
        } else {
            labelText = value;
        }
    
        if (labelText.length > limit) {
            labelText = labelText.substring(0, limit) + '...';
        }
    
        return labelText;
    }
    

    private getAccountDetails(): any {
        const data = localStorage.getItem('account_metadata');
        if (data) {
            this.fieldLabelSubject = JSON.parse(data)?.field_labeling || {};
            return this.globalService.formatFields(this.fieldLabelSubject);
        }
        return null;
    }
}
