<div *ngIf="isLoading" class="{{(isLoading) ? 'loadingOverlay' : ''}}">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>

<div class="custom-message">
  <div class="top-bar">
    <div class="action-fields">
      <div>
        <p-dropdown class="dropdown-width" [(ngModel)]="selectedFilters[filterData[0].key]" inputId="dropdown"
          [placeholder]="filterData[0].filterName" [options]="filterData[0].array">
        </p-dropdown>
      </div>
      <div>
        <p-dropdown class="dropdown-width"  [(ngModel)]="selectedFilters[filterData[1].key]" inputId="dropdown" 
          [placeholder]="filterData[1].filterName" [options]="filterData[1].array">
        </p-dropdown>
      </div>
      <div>
        <p-calendar 
            dataType="date"
            id="inputtext" 
            [(ngModel)]="selectedFilters[filterData[2].key]"     
            class="configure-date-picker-custom-msg"
            selectionMode="range" 
            [readonlyInput]="true"
            [showIcon]="true"
            [placeholder]="filterData[2].filterName">
        </p-calendar>
      </div>
    </div>
    <div class="action-fields">
      <div>
        <span class="clear-all" (click)="clearFilters()">Clear All</span>
      </div>
      <div>
        <span class="apply-filters" (click)="applyFilters()">Apply Filter</span>
      </div>
    </div>
  </div>
  <div>
  
  <!-- search bar and filter fields -->
  <div class="top-bar-search-details">
    <div class="filter-fields">
      <div class="search-bar">
        <em class="pi pi-search" style="font-size: 13px;cursor: pointer"></em>
        <input #inputRef class="input-field" type="text" placeholder="Search by message/subject"
          (keyup.enter)="searchValueChange($event)"
          [style.color]="searchColor ? 'var(--clr-secondary)' : 'var(--clr-black)'" (input)="onSearchChange($event)">
      </div>
    </div>
    <div class="each-link-types">
      <div class="email-types" *ngFor="let each of messageTypes">
        <span class="color-box" [ngStyle]="{'background-color': each.colorCode}"></span>
        <span>{{ each.label }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!campaignData.length && !isLoading" class="empty-data-msg">
    <span class="no-data-text">No data found</span>
  </div>
</div>

  <div class="content">
    <div class="left-panel" (scroll)="onScroll()" #scrollContainer>
      <div *ngFor="let data of campaignData; let i = index" class="each-campaign"
        [class.activeCampaign]="selectedCampaignIndex === i" (click)="getSpecificCampaign(data, i)">
        <div class="email-type-container">
          <span class="{{ campaignTypes[data.campaign_type]?.[1] || 'drives-bg'}}-bar email-type-bar"></span>
        </div>
        <div>
          <div class="campaign-type">{{ data.campaign_type }}</div>
          <div class="campaign-date">{{ data.created_at | date: globalService.defaultDateFormatWithTime }}</div>
        </div>
      </div>
      <div *ngIf="campaignData.length && isScrolling" class="loading-spinner">
        <p-progressSpinner [style]="{height: '20px', width: '20px'}" strokeWidth="7" animationDuration="0.5s"></p-progressSpinner>
      </div>
    </div>
    <div class="right-panel" *ngIf="selectedCampaign">
      <div class="header-content">
        <div class="header-content-title">
        <div>
            <span class="{{ campaignTypes[selectedCampaign.campaign_type]?.[1] || 'drives-bg' }} color-text-bg">{{ campaignTypes[selectedCampaign.campaign_type]?.[0] || 'Drives' }}</span>
          </div>
          <div class="campaign-name">
            <span>
              <span class="to-address">To: </span>{{ selectedCampaign.combinedAddress.length > 20 ?
              (selectedCampaign.combinedAddress.slice(0, 20) + "...") :  selectedCampaign.combinedAddress }}
              <span *ngIf="selectedCampaign.combinedAddress.length > 20" class="open-link" (click)="openDialog('To Address', selectedCampaign.toAddresses)">
                {{ ' Others' }}
              </span>
            </span>
          </div>
        </div>
        <div class="from-address-attachments">
          <div>
              <span [style]="{color: 'var(--clr-secondary)', paddingRight: '10px'}">From Address:  </span>
              <span class="value">{{ selectedCampaign.sender_email }}</span>
          </div>
          <div *ngIf="selectedCampaign.attachments?.length">
            <span [style]="{color: 'var(--clr-secondary)', paddingRight: '10px'}">Attachment(s):  </span>
            <span class="download-link value" (click)="downloadFiles(selectedCampaign.attachments, selectedCampaign.campaign_type)"> Download({{ selectedCampaign.attachments.length }}) </span>
          </div>
        </div>
      </div>
      <div class="email-info">
        <div class="details">
          <div class="each-item">
            <span class="label">Sent</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.sentEmails?.length  }" (click)="openDialog('Sent', selectedCampaign.sentEmails)">
                {{ selectedCampaign.sentEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Delivered</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.DeliveredEmails?.length  }" (click)="openDialog('Delivered', selectedCampaign.DeliveredEmails)">
              {{ selectedCampaign.DeliveredEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Failed</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.FailedEmails?.length  }" (click)="openDialog('Failed', selectedCampaign.FailedEmails)">
              {{ selectedCampaign.FailedEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Bounced</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.BouncedEmails?.length  }" (click)="openDialog('Bounced', selectedCampaign.BouncedEmails)">
              {{ selectedCampaign.BouncedEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Viewed</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.ViewedEmails?.length  }" (click)="openDialog('Viewed', selectedCampaign.ViewedEmails)">
              {{ selectedCampaign.ViewedEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Clicked</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.ClickedEmails?.length  }" (click)="openDialog('Clicked', selectedCampaign.ClickedEmails)">
              {{ selectedCampaign.ClickedEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Suppressed</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.SuppressedEmails?.length  }" (click)="openDialog('Suppressed', selectedCampaign.SuppressedEmails)">
              {{ selectedCampaign.SuppressedEmails?.length || 0 }}
            </span>
          </div>
          <div class="each-item">
            <span class="label">Quarantined</span>
            <span class="value align-center" [ngClass]="{ 'open-link': selectedCampaign.QuarantinedEmails?.length  }" (click)="openDialog('Quarantined', selectedCampaign.Quarantined)">
              {{ selectedCampaign.QuarantinedEmails?.length || 0 }}
            </span>
          </div>
        </div>
      </div>
      <div class="email-content">
        <div class="email-header">
            <span [style]="{color: 'var(--clr-secondary)', paddingRight: '10px'}">Subject Line </span>
            <span class="value">{{ this.subjectTitle }}</span>
        </div>
        <p-divider class="p-divider-width"></p-divider>
        <div class="email-body">
          <span class="value" [innerHTML]="this.templateBody"></span>
        </div>
      </div>
    </div>
    <div class="right-panel" *ngIf="!isLoading && !selectedCampaign && selectedCampaignIndex != -1">
      <div class="empty-data-msg">
        <span class="no-data-text">No data found</span>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Header" [resizable]="false" [draggable]="false" [modal]="true" [(visible)]="showEmailDialog"
  [style]="{ width: '25vw', height: '250px' }">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold header">{{ emailDialogTitle }}</span>
  </ng-template>
  <div class="each-email-in-dialog">
    <div class="each-email-in-list" *ngFor="let email of emailsToDisplay; let i = index">
       {{ i + 1 }}. {{ email }} <br>
    </div>
  </div>
</p-dialog>