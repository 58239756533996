import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface HeaderButton {
  label: string;
  action: () => void;
  type: 'button' | 'splitButton' | 'change_req';
  icon?: string;
  items?: any[];
  position: 'left' | 'right';
}

interface HeaderState {
  currentRoute: string;
  buttons: HeaderButton[];
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private headerStateSubject: BehaviorSubject<HeaderState> = new BehaviorSubject<HeaderState>({
    currentRoute: '',
    buttons: []
  });

  constructor() {}

  get headerState$(): Observable<HeaderState> {
    return this.headerStateSubject.asObservable();
  }

  updateHeaderState(route: string, buttons: HeaderButton[]) {
    this.headerStateSubject.next({ currentRoute: route, buttons });
  }
}