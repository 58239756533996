<div class="custom-calendar-container">
  <p-card>
    <form [formGroup]="dateRangeForm" class="date-range-picker">
      <div class="calendars">
        <div class="calendar-container">
          <label>From Date</label>
          <p-calendar formControlName="fromDate" [inline]="true" [showIcon]="false" (onSelect)="onDateSelect($event)"
            [maxDate]="dateRangeForm.get('toDate')?.value"
            [todayButtonStyleClass]="'p-datepicker-today-hidden'"></p-calendar>
        </div>
        <div class="calendar-container">
          <label>To Date</label>
          <p-calendar formControlName="toDate" [style]="{width:'250px'}" [inline]="true" [showIcon]="false"
            (onSelect)="onDateSelect($event)" [minDate]="dateRangeForm.get('fromDate')?.value"
            [todayButtonStyleClass]="'p-datepicker-today-hidden'"></p-calendar>
        </div>
      </div>

      <div class="selected-range" *ngIf="dateRangeForm.valid">
        Selected Range: {{ formatDate(dateRangeForm.get('fromDate')?.value) }} - {{
        formatDate(dateRangeForm.get('toDate')?.value) }}
      </div>
      
      <div *ngIf="showError" class="selected-range" style="color: crimson;">
        Please select both From and To dates.
      </div>      

      <div class="shortcuts">
        <p-button label="This Week" (onClick)="setThisWeek()" styleClass="p-button-sm p-button-outlined"></p-button>
        <p-button label="This Month" (onClick)="setThisMonth()" styleClass="p-button-sm p-button-outlined"></p-button>
        <p-button label="This Year" (onClick)="setThisYear()" styleClass="p-button-sm p-button-outlined"></p-button>
        <p-button label="Last 1 Day" (onClick)="setLastDay()" styleClass="p-button-sm p-button-outlined"></p-button>
        <p-button label="Last 7 Days" (onClick)="setLast7Days()" styleClass="p-button-sm p-button-outlined"></p-button>
        <p-button label="Last 1 Month" (onClick)="setLastMonth()" styleClass="p-button-sm p-button-outlined"></p-button>
        <p-button label="Last 3 Months" (onClick)="setLast3Months()"
          styleClass="p-button-sm p-button-outlined"></p-button>
      </div>

      <div class="action-buttons">
        <button pButton pRipple label="Set Range" (click)="applyDateRange()"></button>
        <button pButton pRipple label="Clear" class="clear-button" (click)="clearDateRange()"></button>
      </div>
    </form>
  </p-card>
</div>