import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageInsightsComponent } from './message-insights.component';
import { MessageInsightsRoutingModule } from './message-insights-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
@NgModule({
  declarations: [MessageInsightsComponent],
  imports: [
    CommonModule,
    MessageInsightsRoutingModule,
    DropdownModule,
    CalendarModule,
    FormsModule,
    ToastModule,
    ProgressSpinnerModule,
    DividerModule,
    DialogModule
  ],
  exports: [MessageInsightsComponent]
})
export class MessageInsightsModule { 
  constructor() {
  }
}
