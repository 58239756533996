import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendEmailComponent } from './send-email.component';
import { SidebarModule } from 'primeng/sidebar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule, HandleErrorModule } from '@shared';
import { DividerModule } from 'primeng/divider';
import { TabModule, TableComponentModule } from '@shared';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PipeModule } from '@pipes';
import { ChipsModule } from 'primeng/chips';
import { EditorModule } from 'primeng/editor';


@NgModule({
  imports: [
    CommonModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    SidebarModule,
    ButtonModule,
    DividerModule,
    TabModule,
    TableComponentModule,
    NgxDropzoneModule,
    ProgressSpinnerModule,
    PipeModule,
    ChipsModule,
    HandleErrorModule,
    EditorModule
  ],
  declarations: [SendEmailComponent],
  exports: [SendEmailComponent]
})
export class SendEmailModule { }
