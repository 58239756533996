import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundsRoutingModule } from './rounds-routing.module';
import { RoundsComponent } from './rounds.component';
import { SplitterModule } from 'primeng/splitter';
import { TableComponentModule, ButtonModule, TabModule, HandleErrorModule } from '@shared';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton'
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PipeModule } from '@pipes';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { SendEmailModule } from '../send-email/send-email.module';


@NgModule({
  declarations: [
    RoundsComponent
  ],
  imports: [
    PipeModule,
    CommonModule,
    RoundsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    SplitterModule,
    TableComponentModule,
    ButtonModule,
    TabModule,
    DropdownModule,
    TooltipModule,
    SidebarModule,
    AccordionModule,
    InputTextModule,
    ChipsModule,
    CheckboxModule,
    ToastModule,
    TableModule,
    MultiSelectModule,
    ConfirmDialogModule,
    CalendarModule,
    ProgressSpinnerModule,
    DividerModule,
    RadioButtonModule,
    OverlayPanelModule,
    HandleErrorModule,
    InputTextareaModule,
    DialogModule,
    PdfViewerModule,
    DragDropModule,
    SendEmailModule
  ],
  exports: [RoundsComponent]
})
export class RoundsModule { }
