import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

arrayMinLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !Array.isArray(control.value)) {
      return { arrayMinLength: { requiredLength: minLength, actualLength: 0 }};
    }
    const length = control.value.length;
    return length >= minLength ? null : { arrayMinLength: { requiredLength: minLength, actualLength: length }};
  };
}
fromToValidator(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const from = group.get('fixed');
    const to = group.get('variable');
    if (from && to && from.value > to.value) {
      // Directly returning the error on the form group, you could also set this error on individual controls if needed
      return { 'fromToInvalid': true };
    }
    return null;
  };
}

}
