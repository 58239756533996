import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { iconsList } from '@models'
import {
  neoPatIcon,
  sideBarStudentIcon,
  sideBarDriveIcon,
  sideBarEligibilityIcon,
  sideBarEmailTemplateIcon,
  sideBarProgrammeMappingIcon,
  sideBarSettingsIcon,
  sideBarPattern,
  republishQueueIcon,
  drivePlannerIcon,
  reminderIcon,
  customMessageIcon,
  metricsIcon,
  chatIcon
}
from '@assets';
import { GlobalService } from '../../services/global.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnDestroy {
  iconsList: any[] = [];
  path: string = '';
  neoPatIcon: any = neoPatIcon;
  sideBarPattern = sideBarPattern;
  assets_bucket: string = '';
  subscription: any;
  constructor(private router: Router, private location: Location, private globalService: GlobalService) {
    this.formatData();
    const path = this.location.path().split('/')[1]
    this.navClick({label: path})
    this.assets_bucket = this.globalService.bucketName;
    const group_account_id = JSON.parse(localStorage.getItem('account_details') ?? '{}').group_account_id;
    if(group_account_id){
      this.neoPatIcon = `https://storage.cloud.google.com/${this.globalService.publicBucket}/logo/${group_account_id}?${new Date().getTime()}`
    }
    else{
      this.neoPatIcon = neoPatIcon;
    }

    this.subscription = this.globalService.imageUpdate.subscribe((data) => {
      if(!data) return;
      this.neoPatIcon = data;
    })
  }

  formatData() {
    this.globalService.driveLabelSubject.subscribe((label) => {
      let path = this.router.url;
      const {
        drive_label,
      } = label.labeling;

      this.iconsList = [
        // { label: "dashboard", value: 'dashboard', isActive: true },
        { label: "drive-planner", value: drivePlannerIcon, toolTip: 'Drive Planner', isActive: path.includes('drive-planner') },
        { label: `${drive_label.toLowerCase()}`, value: sideBarDriveIcon, toolTip: `${drive_label}`, isActive: path.includes('drives') || path.includes('jobs') },
        { label: 'reminders', value: reminderIcon, toolTip: `${drive_label} Reminder`, isActive: path.includes('reminders') },
        { label: 'students', value: sideBarStudentIcon, toolTip: 'Students', isActive: path.includes('students') },
        { label: 'republish-queue', value: republishQueueIcon, toolTip: 'Republish Queue', isActive: path.includes('republish-queue') },
        { label: 'eligibility', value: sideBarEligibilityIcon, toolTip: 'Eligibility Check', isActive: path.includes('eligibility') },
        { label: 'email-template', value: sideBarEmailTemplateIcon, toolTip: 'Email Templates', isActive: path.includes('email-template') },
        { label: 'email-insights', value: customMessageIcon, toolTip: 'Email Insights', isActive: path.includes('email-insights')},
        { label: 'engagement-metrics', value: metricsIcon, toolTip: 'Engagement Metrics', isActive: path.includes('engagement-metrics')},
        { label: 'chat', value: chatIcon, toolTip: 'Chat', isActive: path.includes('chat') },
        { label: "programme-mapping", value: sideBarProgrammeMappingIcon, toolTip: 'Programme Mapping', isActive: path.includes('programme-mapping') },
        { label: 'settings', value: sideBarSettingsIcon, toolTip: 'Settings', isActive: path.includes('settings') }
      ];
    })
  }
  
  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          let pathname = this.router.url.split('/')[1];
          this.navClick({label : pathname})
        }, 0);
      }
    });
  }
  

  navClick(option: iconsList | {label: string}) {
      setTimeout(() => {
        this.iconsList.forEach((list: any) => {
          list.isActive = (list.label === option.label);
        })
        if(Object.keys(option).length === 1) return
        this.router.navigateByUrl(option.label)
      }, 0);
  }

  imageLoadError(event: any){
    event.target.src = neoPatIcon;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
