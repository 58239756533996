import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {

  constructor(private http: HttpService) { }
  
  async fetchDepartmentProgramme(body: any) {
    const fetchDepartmentProgramme =
      await this.http.post(
        '/eligibilityCriteria/fetchDepartmentProgramme',
        body
      );
    return fetchDepartmentProgramme;
  }

  async getFilterById(body: any) {
    const getFilterById =
      await this.http.post(
        `/eligibilityCriteria/getFilterById`,
        body
      );
    return getFilterById;
  }

  async viewEligibleStudent(body: any) {
    const viewEligibileStudent =
      await this.http.post(
        `/eligibilityCriteria/getEligibleStudents`,
        body
      );
    return viewEligibileStudent;
  }

  async getAllEligibilityFilter(group_account_id: string) {
    const getAllEligibilityFilter =
      await this.http.get(
        `/eligibilityCriteria/getAllEligibilityFilter/${group_account_id}`,
      );
    return getAllEligibilityFilter;
  }

  async fetchFilterDetails(group_account_id: string) {
    const fetchfieldTemplate =
      await this.http.get(
        `/eligibilityCriteria/fetchfieldTemplate/${group_account_id}`,
      );
    return fetchfieldTemplate;
  }

  async createCategory(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/addCategory',
        body
      );
    return addCategory;
  }

  async fetchAllTemplate(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/getAccountTemplate',
        body
      );
    return addCategory;
  }

  async saveFilter(body: any) {
    const saveFilter =
      await this.http.post(
        '/eligibilityCriteria/saveFilter',
        body
      );
    return saveFilter;
  }

  async saveExistingFilter(body: any) {
    const saveExistingFilter =
      await this.http.post(
        '/eligibilityCriteria/saveExistingFilter',
        body
      );
    return saveExistingFilter;
  }

  async getFilteredCandidates(body: any) {
    const getFilteredCandidates =
      await this.http.post(
        '/eligibilityCriteria/getFilteredCandidates',
        body
      );
    return getFilteredCandidates;
  }

  async fetchCategoryTemplate(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/getCategoryTemplate',
        body
      );
    return addCategory;
  }

  async createTemplate(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/addTemplate',
        body
      );
    return addCategory;
  }

  async updateTemplate(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/updateTemplate',
        body
      );
    return addCategory;
  }

  async templateDelete(body: any) {
    const addCategory =
      await this.http.post(
        '/emailTemplate/deleteTemplate',
        body
      );
    return addCategory;
  }

  async getDrivesInRepublishQueue(payload: any) {
    const getDrivesInRepublishQueue =
      await this.http.post(
        '/republish/getDrivesInRepublishQueue',
        payload
      );
    return getDrivesInRepublishQueue;
  }

  async republishDrive(payload: any) {
    const republishDrive =
      await this.http.post(
        '/republish/republishDrive',
        payload
      );
    return republishDrive;
  }
}
