import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private httpService: HttpService) {}

  async fetchChats(payload: any) {
    return await this.httpService.post('/chats', payload);
  }

  async fetchBroadcastInfo(payload: any) {
    return await this.httpService.post('/chats/broadcasts/info', payload);
  }

  async fetchTemplates() {
    return await this.httpService.get('/chats/templates');
  }

  async sendTemplateMessage(payload: any) {
    return await this.httpService.post('/chats/templates/send', payload);
  }

  async getDrives(payload: any) {
    let result = await this.httpService.post(`/drives/getDrives`, payload);
    return result.data;
  }

  async createBroadcasts(payload: any) {
    return await this.httpService.post('/chats/broadcasts/create', payload);
  }

  async getUserSentFile(mediaId: string): Promise<any> {
    return await this.httpService.getBlob(`/chats/files/${mediaId}/download`);
  }
}
