<div>
    <ng-container *ngIf="isMainPage; else routlet">
        <div class="body-cont">
            <div class="side-nav">
                <app-sidebar></app-sidebar>
            </div>
            <div class="main-container">
                <div class="main-header">
                    <app-header></app-header>
                </div>
                <div class="main-body">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #routlet>
        <router-outlet></router-outlet>
    </ng-template>
    <p-toast></p-toast>
</div>
