import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageSymbol'
})
export class PercentageSymbolPipe implements PipeTransform {

  transform(value: any) {
    if(typeof value != 'string') return value;
    return value.replace(/percentage/gi, '%');
  }

}
