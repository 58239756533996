<div *ngIf="isLoading" class="{{ isLoading ? 'loadingOverlay' : '' }}">
  <p-progressSpinner
    [style]="{ height: '1.875rem', width: '1.875rem' }"
    strokeWidth="7"
    animationDuration="0.5s"
  >
  </p-progressSpinner>
</div>
<div *ngIf="isLoadingDetails" class="loading-feedback">
  <p-progressSpinner
    [style]="{ height: '1.875rem', width: '1.875rem' }"
    strokeWidth="7"
    animationDuration="0.5s"
  >
  </p-progressSpinner>
</div>

<div class="chat-info">
  <p-sidebar
    [(visible)]="showSidebar"
    position="right"
    (onHide)="clearInputs()"
    [dismissible]="false"
    [style]="{ width: '22.5rem' }"
  >
    <div class="sidebar-content">
      <div class="profile-header">
        <div class="profile-image-container">
          <img [src]="getProfileImage()" alt="Profile Picture" class="profile-image" />
        </div>
        <div class="header-info">
          <h3 class="header-title">{{ selectedChatDetail.chat?.name }}</h3>
          <span class="header-subtitle">
            {{ chatType === "broadcast" ? "Broadcast Group" : "Personal Chat" }}
          </span>
        </div>
      </div>

      <!-- Broadcast View -->
      <ng-container *ngIf="selectedChat?.broadcast_id">
        <div class="info-section">

          <div class="broadcast-info-highlight section-header">
            <span class="broadcast-label">{{ getLabel(selectedChatDetail?.chat?.type) | driveLabelPipe }}</span>
            <span class="broadcast-value"> - {{ getValue(selectedChatDetail?.chat) }}</span>
          </div>

          <div class="section-header">
            <span class="user-count"
              >{{ broadcastInfo?.length || 0 }} Verified Users</span
            >
          </div>

          <div *ngIf="broadcastInfo?.length" class="user-list">
            <div *ngFor="let user of broadcastInfo" class="user-card">
              <div class="user-card-content">
                <div class="user-image-container">
                  <img
                    [src]="user.profile_picture || chatProfileIcon"
                    alt="User Profile"
                    class="user-image"
                  />
                </div>
                <div class="user-details">
                  <p class="user-name">
                    {{ user.first_name }} {{ user.last_name }}
                  </p>
                  <p class="user-contact">
                    <i class="pi pi-phone"></i>
                    <span>{{ user.mobile_number }}</span>
                  </p>
                  <p class="user-contact">
                    <i class="pi pi-envelope"></i>
                    <span>{{ user.primary_email }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Single Chat View -->
      <ng-container *ngIf="!selectedChat?.broadcast_id">
        <div class="info-section">
          <div class="contact-details">
            <div class="contact-item">
              <i
                class="pi pi-verified verification-icon"
                [ngClass]="{
                  verified:
                    selectedChatDetail.chat?.mobile_no_verified === 'VERIFIED'
                }"
              >
              </i>
              <span class="verification-status">
                {{
                  selectedChatDetail.chat?.mobile_no_verified === "VERIFIED"
                    ? "Verified Account"
                    : "Not Verified"
                }}
              </span>
            </div>
            <div class="contact-item">
              <i class="pi pi-phone"></i>
              <span>{{ selectedChatDetail.chat?.mobile_number }}</span>
            </div>
            <div class="contact-item">
              <i class="pi pi-envelope"></i>
              <span>{{ selectedChatDetail.chat?.primary_email }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </p-sidebar>
</div>

<div class="chat-container" #OVERLYADS *ngIf="isWhatsappEnabled; else whatsappDisabled">
  <div class="chat-list-container">
    <div class="chat-type-container">
      <div class="card flex justify-content-center chat-type-tabs">
        <p-selectButton class="flex align-center" [options]="chatTypeOptions" [(ngModel)]="chatType" optionLabel="label"
          optionValue="value" optionDisabled="constant" (onChange)="chatTypeChange($event)" optionDisabled="disabled" />
      </div>
      <div class="reload-icon" pTooltip="Reload Chats" tooltipPosition="top" (click)="reloadSelectedChat()" [ngClass]="{'disabled': isChangeDisabled}">
        <i class="pi pi-refresh"></i>
      </div>
    </div>
    <div class="chat-list">
      <div class="search-options">
        <div class="search-bar">
          <em class="pi pi-search" style="font-size: 0.813rem; cursor: pointer"></em>
          <input #inputRef [(ngModel)]="searchValue" class="input-field" type="text" [placeholder]="searchPlaceholders"
            (keyup.enter)="searchValueChange($event)" [disabled]="isChangeDisabled" />
        </div>
        <div class="add-broadcast" *ngIf="chatType === 'broadcast'" (click)="newBroadcast()">
          <i class="pi pi-plus"></i>
        </div>
      </div>
      <div class="chat-user-cards" *ngIf="chatList?.length && chatType === 'chats'" (scroll)="onChatScroll()"
        #chatScrollContainer>
        <div *ngFor="let chat of chatList" class="chat-card" (click)="openChat(chat)" [ngClass]="{
              'selected-chat': selectedChat?.mobile_number === chat?.mobile_number
            }">
          <div class="profile-picture">
            <img [src]="chat?.profile_picture || chatProfileIcon" alt="User Profile" />
            <span class="status-dot" [ngClass]="{ active: chat?.isActive, inactive: !chat?.isActive }"></span>
          </div>
          <div class="chat-details">
            <div class="recent-message">
              <span class="user-name">{{
                chat?.name?.length ? chat?.name : "--"
                }}</span>
              <span class="last-timestamp">{{
                chat?.lastTimestamp?.time
                }}</span>
            </div>
            <div class="recent-message">
              <span class="last-message" [innerHTML]="formatSingleMessage(chat?.lastMessage, chat.recent_message)"></span>
              <span class="last-timestamp">{{
                chat?.lastTimestamp?.date
                }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="chatType === 'chats' && isChatScrolling">
          <div class="loading-indicator">
            <div class="loader"></div>
            Loading chats...
          </div>
        </div>
      </div>
      <div *ngIf="isChatScrolling && !chatList?.length">
        <div class="loading-indicator">
          <div class="loader"></div>
          Loading chats...
        </div>
      </div>
      <ng-container *ngIf="chatType === 'chats' && !chatList?.length && !isChatScrolling">
        <span> No chats to display </span>
      </ng-container>
      <div class="chat-user-cards" *ngIf="broadcastList?.length && chatType === 'broadcast'"
        (scroll)="onBroadcastScroll()" #broadcastScrollContainer>
        <div *ngFor="let broadcast of broadcastList" class="chat-card" (click)="openBroadcast(broadcast)" [ngClass]="{
              'selected-chat':
                selectedChat?.broadcast_id === broadcast?.broadcast_id
            }">
          <div class="profile-picture">
            <img [src]="broadcastProfileIcon" alt="Broadcast Profile" />
          </div>
          <div class="chat-details">
            <div class="recent-message">
              <span class="user-name">{{ broadcast?.name ?? "--" }}</span>
              <span class="last-timestamp">{{
                broadcast?.lastTimestamp?.time
                }}</span>
            </div>
            <div class="recent-message">
              <span class="last-message" [innerHTML]="formatSingleMessage(broadcast?.lastMessage)"></span>
              <span class="last-timestamp">{{
                broadcast?.lastTimestamp?.date
                }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="chatType === 'broadcast' && isBroadcastScrolling">
          <div class="loading-indicator">
            <div class="loader"></div>
            Loading broadcasts...
          </div>
        </div>
      </div>
      <div *ngIf="isBroadcastScrolling && !broadcastList?.length">
        <div class="loading-indicator">
          <div class="loader"></div>
          Loading broadcasts...
        </div>
      </div>
      <ng-container *ngIf="
            chatType === 'broadcast' &&
            !broadcastList?.length &&
            !isBroadcastScrolling
          ">
        No broadcasts to display
      </ng-container>
      <div class="chat-user-cards" *ngIf="allChatList?.length && chatType === 'all'" (scroll)="onScroll()"
        #scrollContainer>
        <div *ngFor="let chat of allChatList" class="chat-card"
          (click)="chat?.broadcast_id ? openBroadcast(chat) : openChat(chat)" [ngClass]="{
                              'selected-chat': chat?.broadcast_id ?
                              selectedChat?.broadcast_id === chat?.broadcast_id :
                              selectedChat?.mobile_number === chat?.mobile_number  
                            }">
          <div class="profile-picture">
            <img [src]="chat?.broadcast_id ? broadcastProfileIcon : (chat?.profile_picture || chatProfileIcon)"
              alt="User Profile" />
            <span class="status-dot" [ngClass]="{ active: chat?.isActive, inactive: !chat?.isActive }"
              *ngIf="!chat?.broadcast_id"></span>
          </div>
          <div class="chat-details">
            <div class="recent-message">
              <span class="user-name">{{
                chat?.name?.length ? chat?.name : "--"
                }}</span>
              <span class="last-timestamp">{{
                chat?.lastTimestamp?.time
                }}</span>
            </div>
            <div class="recent-message">
              <span class="last-message" [innerHTML]="formatSingleMessage(chat?.lastMessage, chat.recent_message)"></span>
              <span class="last-timestamp">{{
                chat?.lastTimestamp?.date
                }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="chatType === 'all' && isAllScrolling">
          <div class="loading-indicator">
            <div class="loader"></div>
            Loading all chats...
          </div>
        </div>
      </div>
      <div *ngIf="isAllScrolling && !allChatList?.length">
        <div class="loading-indicator">
          <div class="loader"></div>
          Loading all chats...
        </div>
      </div>
      <ng-container *ngIf="chatType === 'all' && !allChatList?.length && !isAllScrolling">
        <span> No chats to display </span>
      </ng-container>
    </div>
  </div>

  <div
    class="user-chat-container"
    [ngStyle]="{ 'background-image': 'url(' + chatBackground + ')' }"
  >
    <div class="user-header-container">
      <span
        class="profile-picture-header"
        *ngIf="selectedChat && !isEmptyObject(selectedChat)"
      >
        <img
          [src]="getProfileImage()"
          alt="User Profile"
        />
        <span
          *ngIf="!selectedChat?.broadcast_id"
          class="status-dot-header"
          [ngClass]="{
            active: selectedChat?.isActive,
            inactive: !selectedChat?.isActive
          }"
        ></span>
      </span>
      <div
        class="user-name-view-cont"
        *ngIf="selectedChat && !isEmptyObject(selectedChat)"
      >
        <span class="username-header">
          {{ selectedChat?.name ?? "--" }}
          <span class="broadcast-type" *ngIf="selectedChat?.broadcast_id"
            >({{
              selectedChat?.passout_year ||
                selectedChat?.drive_name ||
                selectedChat?.degree_spec_name
            }})</span
          >
        </span>
        <!-- (click)="viewUserInfo(selectedChat)" -->
        <span
          class="user-info-view-header"
          (click)="openChatDetails(selectedChat)"
        >
          Click here to view info
        </span>
      </div>
    </div>
    <div
      class="conversation-container"
      #userChatContainer
      [ngStyle]="{
        padding: '0.938rem',
        'padding-bottom': selectedChat?.isActive ? '5rem' : '7.5rem'
      }"
    >
      <div #conversationContainer class="messages">
        <ng-container *ngIf="isEmptyObject(selectedChat)">
          <div class="loading-indicator">
            Select a {{ chatType === "chats" || chatType === 'all' ? "chat" : "broadcast" }} to view
            conversation...
          </div>
        </ng-container>
        <ng-container *ngIf="isChatLoading">
          <div class="loading-indicator">
            <div class="loader"></div>
            Loading conversations...
          </div>
        </ng-container>
        <ng-container *ngIf="!isChatLoading">
          <div
            *ngFor="let group of selectedChatConversations?.chats"
            class="date-group"
          >
            <div class="date-divider">
              <span>{{ group.date }}</span>
            </div>
            <div
              *ngFor="let message of group.messages"
              class="message-wrapper"
              [ngClass]="{
                received: message.direction === 'received',
                delivered: message.direction === 'delivered'
              }"
            >
              <div
                *ngIf="
                  message.direction === 'received' &&
                  (message?.body || message?.media_url)
                "
                class="avatar"
              >
                <img
                  [src]="
                    selectedChatConversations?.profile_picture ||
                    chatProfileIcon
                  "
                  alt="User Avatar"
                />
              </div>

              <div
                class="message-bubble"
                [ngClass]="{
                  received: message.direction === 'received',
                  delivered: message.direction === 'delivered'
                }"
                *ngIf="message?.body || message?.media_url"
              >
                <!-- Tagged Reply -->
                <ng-container *ngIf="message.context">
                  <div class="tagged-reply" *ngIf="!message?.context?.forwarded">
                    <span class="reply-header">NeoPAT</span>
                    <span *ngIf="getOriginalMessage(
                                      message?.context?.id || message?.context?.message_id
                                    )
                                  " [innerHTML]="
                                    getOriginalMessage(
                                      message.context.id || message?.context?.message_id
                                    )
                                  ">
                    </span>
                  </div>
                  <div *ngIf="message?.context?.forwarded" class="forwarded-text">
                    <img width="18" height="18" src="https://img.icons8.com/fluency-systems-regular/50/forward-arrow.png" alt="forward-arrow"/>
                    <span>Forwarded</span>
                  </div>
                </ng-container>

                <!-- Header -->
                <ng-container
                  *ngIf="message.type === 'template' && message.header"
                >
                  <div class="message-header">
                    <strong>{{ message.header }}</strong>
                  </div>
                </ng-container>

                <!-- Media Preview -->
                <ng-container *ngIf="isMediaType(message?.type)">
                  <div class="media-preview">
                    <div class="mediaIcon">
                      <i [ngClass]="getFileIconClass(message?.type)"></i>
                    </div>
                    <div class="media-content">
                      <span
                        class="media-filename"
                        [title]="message?.fileName"
                      >
                        {{
                          truncateFileName(message?.fileName,
                            20
                          )
                        }}
                      </span>
                      <a
                        *ngIf="message?.media_url"
                        (click)="downloadFile(message)"
                        class="download-button"
                      >
                        <i class="pi pi-download"></i>
                      </a>
                    </div>
                  </div>
                </ng-container>

                <!-- Message Header -->
                <span [innerHTML]="message?.header"></span>
                <span *ngIf="message?.header"><br /><br /></span>

                <!-- Message Body -->
                <span [innerHTML]="message.body"></span>

                <!-- Reply Button inside the message bubble -->
                <div
                  *ngIf="
                    message?.direction === 'received' && selectedChat?.isActive
                  "
                  class="reply-button"
                >
                  <i
                    class="pi pi-angle-down"
                    (click)="toggleMessageOptions(message.message_id)"
                  ></i>

                  <div
                    class="message-options"
                    *ngIf="showMessageOptions === message.message_id"
                  >
                    <button (click)="setReplyContext(message?.message_id)">
                      Reply
                    </button>
                  </div>
                </div>

                <!-- Timestamp and Footer -->
                <div
                  class="timestamp"
                  [ngClass]="{
                    'contains-footer': message?.direction === 'delivered'
                  }"
                >
                  <ng-container *ngIf="message?.direction === 'delivered'">
                    <div class="message-footer">NeoPAT</div>
                  </ng-container>
                  <span>{{ message.lastTimestamp.time }}</span>
                </div>

                <!-- Buttons -->
                <ng-container *ngIf="message.buttons && message.buttons.length">
                  <div class="render-buttons">
                    <ng-container *ngFor="let button of message.buttons">
                      <div class="button-group">
                        <button disabled="true">{{ button.text }}</button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="floating-message-area"
      *ngIf="
        !isEmptyObject(selectedChat) && selectedChat?.isActive && !isChatLoading
      "
      [ngStyle]="{ height: isMultiline || replyContext ? '17%' : '7%' }"
    >
      <div *ngIf="replyContext" class="reply-preview">
        <div class="reply-message">
          <span class="reply-label">Replying to:</span>
          <span
            class="reply-content"
            [innerHTML]="
              getOriginalMessage(replyContext).length > 150
                ? getOriginalMessage(replyContext).slice(0, 150) + '...'
                : getOriginalMessage(replyContext)
            "
          >
          </span>
        </div>
        <i
          class="pi pi-times cancel-reply-button"
          (click)="clearReplyContext()"
        ></i>
      </div>

      <div *ngIf="filePreviews.length > 0" class="file-preview-area">
        <div *ngFor="let preview of filePreviews" class="file-preview">
          <i
            [ngClass]="getFileIconClass(preview.fileType)"
            class="file-icon"
          ></i>
          <span class="file-name">{{ preview.fileName }}</span>
          <i
            class="pi pi-times cancel-file-button"
            (click)="removeFilePreview(preview)"
          ></i>
        </div>
      </div>

      <div *ngIf="!isMultiline" style="width: 100%">
        <input
          #messageInput
          class="message-input"
          [(ngModel)]="messageText"
          type="text"
          placeholder="Type a message"
          (keydown)="handleKeyDown($event, 'single')"
          [disabled]="filePreviews.length > 0"
        />
      </div>

      <div *ngIf="isMultiline" style="width: 100%; height: 90%">
        <textarea
          #multilineInput
          class="message-input"
          [(ngModel)]="messageText"
          placeholder="Type a longer message"
          (keydown)="handleKeyDown($event, 'multiline')"
          [style.overflowY]="messageText ? 'auto' : 'hidden'"
          (input)="autoResize(multilineInput)"
          [disabled]="filePreviews.length > 0"
        >
        </textarea>
      </div>

      <div class="file-picker">
        <i
          class="pi pi-align-left multiline-icon"
          (click)="toggleMultiline()"
          [ngClass]="{ 'disabled-icon': filePreviews.length > 0 }"
        ></i>

        <i
          class="pi pi-paperclip"
          (click)="toggleFileOptions()"
          [ngClass]="{
            'disabled-icon':
              filePreviews.length > 0 || this.messageText.trim().length
          }"
        ></i>

        <div class="file-options" *ngIf="showFileOptions">
          <button (click)="selectFileType('image')">Image</button>
          <button (click)="selectFileType('video')">Video</button>
          <button (click)="selectFileType('document')">Document</button>
        </div>

        <button class="send-button" (click)="sendMessage()">
          <i class="pi pi-send"></i>
        </button>

        <input
          type="file"
          #imageInput
          accept="image/png, image/jpeg"
          style="display: none"
          (change)="handleFileUpload($event, 'image')"
        />
        <input
          type="file"
          #videoInput
          accept="video/3gp, video/mp4"
          style="display: none"
          (change)="handleFileUpload($event, 'video')"
        />
        <input
          type="file"
          #documentInput
          accept=".pdf,.doc,.docx,.txt,.pptx,.ppt,.xls,.xlsx"
          style="display: none"
          (change)="handleFileUpload($event, 'document')"
        />
      </div>
    </div>
    <div
      class="floating-template-area"
      *ngIf="shouldShowFloatingTemplateArea && !isChatLoading"
    >
      <span class="inactive-message">
        {{
        chatType === "chats"
        ? "This user's WhatsApp session has expired. To re-establish contact, send an outbound template message below. Oncethe user responds, the session will become active again."
        : chatType === "broadcast"
        ? "Broadcast messages are one-way and do not allow responses. Use the template message to reach the selected group."
        : selectedChatConversations?.type === 'broadcast' ? "Broadcast messages are one-way and do not allow responses. Use the template message to reach the selected group." : 
        "This user's WhatsApp session has expired. To re-establish contact, send an outbound template message below. Once the user responds, the session will become active again."
        }}
      </span>

      <div class="send-template-button" #targetEl>
        <p-button
          #templateButton
          label="Send Template Message"
          styleClass="p-button-outlined"
          icon="pi pi-send"
          iconPos="right"
          (click)="op.toggle($event, targetEl)"
          [disabled]="chatType === 'broadcast' && !broadcastInfo?.length"
          [pTooltip]="
            chatType === 'broadcast' && !broadcastInfo?.length
              ? 'Messages can be sent only if verified users are present in the broadcast.'
              : ''
          "
          tooltipPosition="top"
        >
        </p-button>
      </div>
    </div>
  </div>

  <p-dialog
    header="New Broadcast"
    [(visible)]="openNewBroadcast"
    class="broadcast-sidebar"
    [closable]="true"
    [modal]="true"
    (onHide)="onBroadcastDialogClose()"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="form-container">
      <div class="form-inputs">
        <div class="form-group">
          <label for="broadcastName"
            >Broadcast Name <span class="required">*</span></label
          >
          <input
            id="broadcastName"
            type="text"
            pInputText
            [(ngModel)]="broadcastName"
            placeholder="Enter Broadcast Name"
          />
        </div>

        <div class="form-group">
          <label for="broadcastBy"
            >Broadcast By <span class="required">*</span></label
          >
          <p-dropdown
            id="broadcastBy"
            [options]="broadcastOptions"
            [(ngModel)]="broadcastBy"
            placeholder="Select an option"
            (onChange)="onBroadcastTypeChange()"
            appendTo="body"
          ></p-dropdown>
        </div>

        <div class="form-group" *ngIf="broadcastBy">
          <label for="selectDrive">
            {{ getDropdownLabel(broadcastBy) | driveLabelPipe }}
            <span class="required">*</span>
          </label>
          <p-dropdown
            id="selectDrive"
            [options]="broadcastCreateOptions"
            [(ngModel)]="selectedBroadcastType"
            placeholder="{{ getDropdownLabel(broadcastBy) | driveLabelPipe }}"
            [filter]="true"
            [showClear]="true"
            appendTo="body"
          ></p-dropdown>
          <p-dropdown
            *ngIf="broadcastBy === 'degree_spec'"
            id="selectDrive"
            [options]="passoutYearsOptions"
            [(ngModel)]="selectedPassoutYear"
            placeholder="Select Passout Year"
            [filter]="true"
            [showClear]="true"
            appendTo="body"
          ></p-dropdown>
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="form-submit-btn">
        <button
          pButton
          label="Create"
          [disabled]="isBroadcastValid()"
          (click)="confirmBroadcast()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>

  <p-overlayPanel
    #op
    [showCloseIcon]="true"
    (onHide)="clearSelectedTemplate()"
    [appendTo]="OVERLYADS"
  >
    <ng-template pTemplate="content">
      <div *ngIf="!selectedTemplate" class="select-template">
        <span style="font-size: medium">Select a Template</span>
        <p-dropdown
          [options]="templateDataOptions"
          [(ngModel)]="selectedTemplate"
          [placeholder]="'Select a template'"
          (onChange)="selectTemplate($event.value)"
        >
        </p-dropdown>
      </div>

      <div
        *ngIf="selectedTemplate; else noSelectedTemplate"
        class="template-container"
      >
        <span style="font-size: medium"
          ><strong style="color: #007ad9">Template:</strong>
          {{ selectedTemplate.name }}</span
        >

        <p-divider
          [ngStyle]="{ width: '100%' }"
          layout="horizontal"
        ></p-divider>

        <div
          *ngFor="let component of selectedTemplate.components"
          class="component-container"
        >
          <!-- Header Placeholder -->
          <div *ngIf="component.type === 'HEADER'" class="placeholders">
            <span style="font-size: 1rem; font-weight: bold; color: #007ad9"
              >Header Section</span
            >
            <span
              [innerHTML]="boldAndColorText(component.text)"
              style="font-size: 0.875rem; display: block; margin-top: 0.313rem"
            ></span>
            <div
              *ngIf="hasPlaceholders(component.text); else noHeaderPlaceholders"
              class="placeholder-container"
            >
              <span style="font-size: 0.875rem; color: #007ad9; font-weight: 600"
                >Fill Header Placeholders:</span
              >
              <div class="placeholder-content">
                <ng-container
                  *ngFor="
                    let placeholder of getPlaceholders(component.text);
                    let i = index
                  "
                >
                  <div class="placeholder-item">
                    <label style="align-self: center">{{ i + 1 }}:</label>
                    <input type="text" [(ngModel)]="headerPlaceholders[i]" />
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-template #noHeaderPlaceholders>
              <span style="color: red"
                >No placeholders available for this header template.</span
              >
            </ng-template>
          </div>

          <!-- Body Placeholder -->
          <div *ngIf="component.type === 'BODY'" class="placeholders">
            <span style="font-size: 1rem; font-weight: bold; color: #007ad9"
              >Body Section</span
            >
            <span
              [innerHTML]="boldAndColorText(component.text)"
              style="font-size: 0.875rem; display: block; margin-top: 0.313rem"
            ></span>
            <div
              *ngIf="hasPlaceholders(component.text); else noBodyPlaceholders"
              class="placeholder-container"
            >
              <span style="font-size: 0.875rem; color: #007ad9; font-weight: 600"
                >Fill Body Placeholders:</span
              >
              <div class="placeholder-content">
                <ng-container
                  *ngFor="
                    let placeholder of getPlaceholders(component.text);
                    let i = index
                  "
                >
                  <div class="placeholder-item">
                    <label style="align-self: center">{{ i + 1 }}:</label>
                    <input type="text" [(ngModel)]="bodyPlaceholders[i]" />
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-template #noBodyPlaceholders>
              <span style="color: red"
                >No placeholders available for this body template.</span
              >
            </ng-template>
          </div>

          <!-- Button Placeholder -->
          <div *ngIf="component.type === 'BUTTON'" class="placeholders">
            <span style="font-size: 1rem; font-weight: bold; color: #007ad9"
              >Button Section</span
            >
            <span
              [innerHTML]="boldAndColorText(component.text)"
              style="font-size: 0.875rem; display: block; margin-top: 0.313rem"
            ></span>
            <div
              *ngIf="hasPlaceholders(component.text); else noButtonPlaceholders"
              class="placeholder-container"
            >
              <span style="font-size: 0.875rem; color: #007ad9; font-weight: 600"
                >Fill Button Placeholders:</span
              >
              <div class="placeholder-content">
                <ng-container
                  *ngFor="
                    let placeholder of getPlaceholders(component.text);
                    let i = index
                  "
                >
                  <div class="placeholder-item">
                    <label style="align-self: center">{{ i + 1 }}:</label>
                    <input type="text" [(ngModel)]="buttonPlaceholders[i]" />
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-template #noButtonPlaceholders>
              <span style="color: red"
                >No placeholders available for this button template.</span
              >
            </ng-template>
          </div>
        </div>

        <span style="font-size: 0.875rem; color: #007ad9; font-weight: 600"
          >Preview:</span
        >

        <div class="preview-container">
          <div class="message-bubble">
            <span [innerHTML]="boldText(getPreview(selectedTemplate))"></span>
          </div>
        </div>

        <button
          (click)="sendTemplateMessage(selectedTemplate, op)"
          class="send-template-button"
        >
          Send
        </button>
      </div>

      <ng-template #noSelectedTemplate>
        <div class="no-template-container">
          <i class="pi pi-envelope" style="font-size: 2rem; color: #007ad9"></i>
          <h3 style="margin-top: 0.625rem; color: #007ad9">No Template Selected</h3>
          <p
            style="
              color: #666;
              text-align: center;
              max-width: 18.75rem;
              margin: 0.625rem auto;
            "
          >
            Please select a template to view its details and fill in the
            placeholders. Once selected, you can preview and send the message.
          </p>
        </div>
      </ng-template>
    </ng-template>
  </p-overlayPanel>

  <p-toast></p-toast>
</div>

<ng-template #whatsappDisabled>
  <div class="chat-container disabled-container">
    <div class="reload-message">
        <span (click)="routeToSettings()" class="action-link"><u>Click here</u>to enable
          WhatsApp notifications in settings and access the chat interface.</span>
      <span (click)="reloadUI()" class="action-link blue-text"><i class="pi pi-refresh"></i>Reload</span>
    </div>
  </div>
</ng-template>