import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-handle-error',
  templateUrl: './handle-error.component.html',
  styleUrls: ['./handle-error.component.css']
})
export class HandleErrorComponent {

  @Input() control: any;
  @Input() ngModelValue: any = true;
  @Input() name: string = '';
  @Input() clicked: boolean = false;
  
  getError(): string | null {
    if (!this.control?.errors) {
      return null;
    }

    const errorKeys = Object.keys(this.control.errors);
    for (const key of errorKeys) {
      if (key === 'required') {
        return `${this.name} is required`;
      } else if (key === 'arrayMinLength') {
        const { actualLength, requiredLength } = this.control.errors[key];
        if (actualLength < requiredLength) {
          return `${this.name} must have at least ${requiredLength} entries.`;
        }
      } else if (key === 'min') {
        return `${this.name} must be greater than ${this.control.errors[key].min}`;
      } else if (key === 'max') {
        return `${this.name} must be less than ${this.control.errors[key].max}`;
      }
      else if(key == 'invalidCharacter') {
        return `${this.name} contains invalid character`;
      }
    }

    return null;
  }
}
