import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';
@Pipe({
  name: 'driveLabelPipe'
})
export class DriveLabelPipe implements PipeTransform {

  private driveLabelSubject: any = {};

  constructor(public globalService: GlobalService) {
    this.getAccountDetails();
  }

  transform(value: any) {
    if (!this.driveLabelSubject || typeof value !== 'string') {
        return value;
    }

    const { label_singular, optin_past, optout_past } = this.globalService.getLabels(this.driveLabelSubject);
    const { salary_label, opt_out_label, opt_in_label, drive_label } = this.driveLabelSubject;

    const replacements = [
        { search: 'Drive', replace: label_singular },
        { search: 'Drives', replace: drive_label },
        { search: 'Salary', replace: salary_label },
        { search: 'Opt-Out', replace: opt_out_label },
        { search: 'Opt-In', replace: opt_in_label },
        { search: 'Opted-In', replace: optin_past },
        { search: 'Opted In', replace: optin_past },
        { search: 'Opted-Out', replace: optout_past },
        { search: 'Opted Out', replace: optout_past }
    ];

    replacements.forEach(({ search, replace }) => {
        if (value.includes(search)) {
            value = value.replaceAll(search, replace);
        }
    });

    return value;
}


  private getAccountDetails() {
    const data = localStorage.getItem('account_metadata');
    if (data) {
      this.driveLabelSubject = JSON.parse(data)?.labeling || {};
    }
  }
}
