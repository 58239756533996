export interface IRoundDates {
    round_type: string;
    round_date_status: string;
    round_date: (Date | null)[];
}

export const RoundTypes = [
    // { label: 'Group Discussion', value: 'group_discussion' },
    // { label: 'Machine Test', value: 'machine_test' },
    // { label: 'Pre-Placement Talk', value: 'pre_placement_talk' }
    { label: 'Online Screening/Aptitude Test', value: 'online_screening', isActive: false },
    { label: 'Group Discussion', value: 'group_discussion', isActive: false },
    { label: 'Technical Coding', value: 'technical_coding', isActive: false },
    { label: 'Technical Interview', value: 'technical_interview', isActive: false },
    { label: 'HR Interview', value: 'hr_interview', isActive: false }
];

export const RoundOptions = [
    { label: 'All', value: 'all', isActive: true },
    { label: 'Online Screening/Aptitude Test', value: 'online_screening', isActive: false },
    { label: 'Group Discussion', value: 'group_discussion', isActive: false },
    { label: 'Technical Coding', value: 'technical_coding', isActive: false },
    { label: 'Technical Interview', value: 'technical_interview', isActive: false },
    { label: 'HR Interview', value: 'hr_interview', isActive: false },
    { label: 'Event', value: 'event', isActive: false }
]

export const roundColors: { [key: string]: string } = {
    "online_screening": "rgb(255,173,58)",
    "group_discussion": "rgb(160,173,58)",
    "technical_coding": "rgb(255,153,158)",
    "technical_interview": "rgba(3, 155, 229, 0.85)",
    "hr_interview": "rgba(121, 134, 203, 0.85)",
    // "event": "#9C4C67"
}