import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Breadcrumb, EBtnProperties } from '@enum';
import { btnProperties } from '@models';
import { GlobalService } from '@services';
import { TableComponent } from 'src/app/shared/components/table/table.component';
import { HeaderService } from '@services';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { chatBackground } from '@assets';
import { ChatService } from '@services';
import { chatProfileIcon, broadcastProfileIcon } from '@assets';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FileUploadService } from '@services';
import { MessageService } from 'primeng/api';
import { Severity, Summary, Message, PassoutYearsOptions } from '@enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reminder-queue',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @ViewChild('actionTemplate') actionTemplateRef!: TemplateRef<any>;
  @ViewChild('userChatContainer') userChatContainer!: ElementRef;
  @ViewChild('messageInput') messageInput!: ElementRef;
  @ViewChild('imageInput') imageInput!: ElementRef<HTMLInputElement>;
  @ViewChild('videoInput') videoInput!: ElementRef<HTMLInputElement>;
  @ViewChild('documentInput') documentInput!: ElementRef<HTMLInputElement>;
  @ViewChild('templateButton', { static: false }) templateButton!: ElementRef;
  @ViewChild('broadcastScrollContainer') broadcastScrollContainer!: ElementRef;
  @ViewChild('chatScrollContainer') chatScrollContainer!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  userData: any = localStorage.getItem('user_details');
  accountData: any = localStorage.getItem('account_details');
  accountMetaData: any = localStorage.getItem('account_metadata');
  btnProperties: { [key: string]: btnProperties } = EBtnProperties;
  isLoading: boolean = false;
  isLoadingDetails: boolean = false;
  searchValue: any = '';
  group_account_id: string;
  driveLabel: string = '';
  chatBackground = chatBackground;
  currentChatScroll: number = 0;
  currentAllScroll: number = 0;
  currentBroadcastScroll: number = 0;
  limit: number = 10;
  allChatList: any = [];
  chatList: any = [];
  broadcastList: any = [];
  templateData: any = {};
  chatProfileIcon = chatProfileIcon;
  broadcastProfileIcon = broadcastProfileIcon;
  passoutYearsOptions = PassoutYearsOptions;
  selectedChat: any = {};
  selectedChatConversations: any = {};
  isChatLoading: boolean = false;
  isBold = false;
  messageText: string = '';
  showFileOptions = false;
  showFileInput = false;
  fileInputAccept = '';
  isMultiline: boolean = false;
  showMultilineOverlay: boolean = false;
  replyContext: string | null = null;
  showMessageOptions: any = '';
  selectedFileType: any = '';
  selectedFile: File | null = null;
  filePreviews: { file: File; fileType: string; fileName: string }[] = [];
  isFileUploading: boolean = false;
  sendingMessage: boolean = false;
  showTemplateDialog: boolean = false;
  dialogStyle: { [key: string]: string } = {};
  selectedTemplate: any = null;
  placeholders: string[] = [];
  templateDataOptions: any;
  fetchInterval: any;
  bodyPlaceholders: any = [];
  buttonPlaceholders: any = [];
  headerPlaceholders: any = [];
  loading: boolean = false;
  hasMoreData: boolean = true;
  chatType: string = 'all';
  openNewBroadcast: boolean = false;
  broadcastName: string = '';
  broadcastBy: any;
  selectedBroadcastType: any;
  broadcastCreateOptions: any = [];
  searchPlaceholders = 'Broadcast Name, Email, Registration No';
  isChatScrolling: boolean = false;
  isAllScrolling: boolean = false;
  isChangeDisabled: boolean = false;
  isBroadcastScrolling: boolean = false;
  broadcastInfo: any = [];
  showSidebar: boolean = false;
  selectedChatDetail: any = {};
  selectedPassoutYear: string = '';
  broadcastOptions: any = [];
  isWhatsappEnabled: boolean = false;
  fileTypes: string[] = ['image', 'video', 'document'];
  docMaxSize: any = {
    'document': '100 MB',
    'image': '5 MB',
    'video': '16 MB'
  }
  chatTypeOptions = [
    { label: 'All', value: 'all', disabled: true },
    { label: 'Chats', value: 'chats', disabled: true },
    { label: 'Broadcast', value: 'broadcast', disabled: true }
  ];

  constructor(
    private fileUploadService: FileUploadService,
    public globalService: GlobalService,
    private headerService: HeaderService,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.accountData = JSON.parse(this.accountData);
    this.userData = JSON.parse(this.userData);
    this.group_account_id = this.accountData.group_account_id;
    this.formatData();
  }

  async ngOnInit() {
    this.isWhatsappEnabled = JSON.parse(this.accountMetaData)?.general?.wp_integration;

    if(!this.isWhatsappEnabled)
    {
      return;
    }

    const templates = await this.chatService.fetchTemplates();
    this.templateData = templates?.data;
    this.templateDataOptions = this.formTemplateDataOptions();
    this.chatTypeChange({ value: this.chatType });
    this.updateBreadCrumb();
  }

  formatData()
  {
    this.globalService.driveLabelSubject.subscribe((label) => {
      const {
        drive_label,
      } = label.labeling;
      const { label_singular, optin_past, optout_past } =
        this.globalService.getLabels(label.labeling);
      this.driveLabel = drive_label;
      this.broadcastOptions = [{ label: this.driveLabel, value: 'drives' },
        { label: 'Degree Specialisation', value: 'degree_spec' },
        { label: 'Passout Year', value: 'passout_year' }];
    
      });
      this.headerService.updateHeaderState('/chat', []);
  }

  async fetchChats() {
    if (this.isChatScrolling) return;

    this.isChatScrolling = true;

    let newChats: any = await this.chatService.fetchChats({
      group_account_id: this.group_account_id,
      offset: this.currentChatScroll++,
      limit: this.limit,
      type: 'chats'
    });

    this.hasMoreData = newChats?.hasMoreData;
    this.chatList = [...this.chatList, ...this.processFetchedChats(newChats?.data)];
    this.chatList = this.sortArrayByTimestamp(
      this.chatList,
      'lastTimestamp',
      'desc'
    );
    this.isChatScrolling = false;

    return true;
  }

  async fetchBroadcasts(fromStart?: boolean) {
    if (this.isBroadcastScrolling) {
      return;
    }

    this.isBroadcastScrolling = true;
    let res: any = await this.chatService.fetchChats({
      group_account_id: this.group_account_id,
      search: this.searchValue,
      ...(fromStart ? {} : { limit: this.limit }),
      ...(fromStart ? {} : { offset: this.currentBroadcastScroll++ }),
      type: 'broadcasts'
    });
    this.isBroadcastScrolling = false;

    if (res.length < this.limit) {
      this.hasMoreData = false;
    }

    this.broadcastList = [
      ...this.broadcastList,
      ...this.processFetchedChats(res),
    ];
    this.broadcastList = this.sortArrayByTimestamp(
      this.broadcastList,
      'lastTimestamp',
      'desc'
    );

  }

  async fetchAllCommunication(fromStart?: boolean)
  {
    if (this.isAllScrolling) {
      return;
    }

    this.isAllScrolling = true;
    let res: any = await this.chatService.fetchChats({
      group_account_id: this.group_account_id,
      search: this.searchValue,
      ...(fromStart ? {} : { limit: this.limit }),
      ...(fromStart ? {} : { offset: this.currentAllScroll++ }),
      type: 'all'
    });
    this.isAllScrolling = false;
    this.hasMoreData = res?.hasMoreBroadcasts || res?.hasMoreChats;
    this.allChatList = [
      ...this.allChatList,
      ...this.processFetchedChats([...res?.broadcasts, ...res?.chats]),
    ];
    this.allChatList = this.sortArrayByTimestamp(
      this.allChatList,
      'lastTimestamp',
      'desc'
    );
  }

  reloadSelectedChat() {
    this.searchValueChange({
      target: {
        value: ''
      }
    })
  }

  private sortArrayByTimestamp(
    array: any[],
    timestampFieldPath: string,
    order: 'asc' | 'desc' = 'desc'
  ): any[] {
    return array?.sort((a: any, b: any) => {
      const timestampA = this.getTimestamp(a, timestampFieldPath);
      const timestampB = this.getTimestamp(b, timestampFieldPath);

      return order === 'asc'
        ? timestampA - timestampB
        : timestampB - timestampA;
    });
  }

  private getTimestamp(object: any, fieldPath: string): number {
    const fieldParts = fieldPath.split('.');
    const timestampObj = fieldParts.reduce(
      (acc, part) => acc && acc[part],
      object
    );
    if (timestampObj && timestampObj.date && timestampObj.time) {
      return new Date(`${timestampObj.date} ${timestampObj.time}`).getTime();
    }
    return 0; // Return 0 if timestamp is invalid or missing
  }

  async onChatScroll() {
    const scrollTop = this.chatScrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.chatScrollContainer.nativeElement.scrollHeight;
    const containerHeight =
      this.chatScrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight && this.hasMoreData) {
      await this.fetchChats();
    }
  }

  async onScroll() {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const containerHeight =
      this.scrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight && this.hasMoreData) {
      await this.fetchAllCommunication();
    }
  }

  async onBroadcastScroll() {
    const scrollTop = this.broadcastScrollContainer.nativeElement.scrollTop;
    const scrollHeight =
      this.broadcastScrollContainer.nativeElement.scrollHeight;
    const containerHeight =
      this.broadcastScrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight && this.hasMoreData) {
      await this.fetchBroadcasts();
    }
  }

  private scrollToBottom(): void {
    try {
      if (this.userChatContainer) {
        const element = this.userChatContainer.nativeElement;
        this.userChatContainer.nativeElement.scrollTo({
          top: element.scrollHeight,
          behavior: 'instant',
        });
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  private formTemplateDataOptions() {
    let res: any = this.templateData.map((template: any) => {
      return {
        label: template?.name,
        value: template,
      };
    });
    return res;
  }

  processFetchedChats(chats: any) {
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const last24HoursInSeconds = nowInSeconds - 24 * 60 * 60;

    return chats?.map((chat: any) => {
      let formattedMessage = this.formatMessage(chat?.recent_message);
      let lastMessage = formattedMessage?.header || formattedMessage?.body;

      if (!lastMessage) {
        if (this.fileTypes.includes(formattedMessage?.type?.toLowerCase())) {
          lastMessage = `<i class="pi pi-file"></i> ${this.toTitleCase(
            formattedMessage?.type
          )}`;
        }
      }

      let received_timestamps = chat?.chats?.map(
        (ch: any) => ch?.received_timestamp
      );

      const isActive = received_timestamps?.some((timestamp: number) => {
        return timestamp > last24HoursInSeconds;
      });

      return {
        ...chat,
        lastMessage:
          lastMessage?.length > 45
            ? lastMessage.slice(0, 45) + '...'
            : lastMessage,
        lastTimestamp: formattedMessage?.lastTimestamp,
        isActive: isActive,
      };
    });
  }

  formatMessage(message: any): any {
    if (!message) return {};

    const {
      message_data,
      type,
      received_timestamp,
      delivered_timestamp,
      message_id,
      delivery_status
    } = message;

    let formattedMessage = {
      header: '',
      body: '',
      footer: '',
      buttons: [],
      media_url: '',
      type: '',
      fileName: ''
    };

    switch (type?.toLowerCase()) {
      case 'template':
        const template = this.formatTemplateMessage(message_data);
        formattedMessage.header = template.header;
        formattedMessage.body = template.body;
        formattedMessage.footer = template.footer;
        formattedMessage.buttons = template.buttons;
        break;

      case 'interactive':
        const interactive = this.formatInteractiveMessage(message_data);
        formattedMessage.body = interactive.body;
        formattedMessage.footer = interactive.footer;
        formattedMessage.buttons = interactive.buttons;
        break;

      case 'text':
        formattedMessage.body = this.formatTextMessage(message_data);
        break;

      case 'button':
        formattedMessage.body = JSON.parse(message_data)?.button?.text || '';
        break;

      case 'image':
      case 'video':
      case 'document':
        const messageData = JSON.parse(message_data);
        const messageType = type.toLowerCase()
        formattedMessage.type = messageType;
        formattedMessage.media_url = messageData?.[messageType]?.[delivery_status === 'Received' ? 'id' :'link'] || '';
        formattedMessage.fileName = delivery_status === 'Received' ? (messageData?.[messageType]?.filename || messageData?.[messageType]?.id) : this.getFileNameFromUrl(messageData?.[messageType]?.['link']);
        break;
      default:
        formattedMessage.body = 'No preview available';
    }

    const messageKeys = Object.keys(
      JSON.parse(this.sanitizeMessageData(message_data))
    );
    const timestamp = received_timestamp || delivered_timestamp;
    const formattedTimestamp = this.formatUnixTimestamp(timestamp, 'custom');
    const direction = received_timestamp ? 'received' : 'delivered';

    return {
      ...formattedMessage,
      lastTimestamp: {
        date: formattedTimestamp.split(', ')[0],
        time: formattedTimestamp.split(', ')[1],
      },
      direction,
      message_id,
      ...(messageKeys.includes('context')
        ? {
            context: JSON.parse(this.sanitizeMessageData(message_data)).context,
          }
        : {}),
    };
  }

  private formatTemplateMessage(templateMessage: any): any {
    try {
      const { template } = JSON.parse(templateMessage);
      const templateDefinition = this.templateData.find(
        (data: any) => data?.name === template.name
      );

      if (!templateDefinition) {
        console.warn(
          `No matching template found for template name: ${template.name}`
        );
        return null;
      }

      const messageParts: any = {
        header: '',
        body: '',
        buttons: [],
      };

      // components with no variables
      if (!template?.components?.length) {
        templateDefinition.components.forEach((defn: any) => {
          if (defn.text && defn?.type !== 'FOOTER') {
            messageParts.body += `${this.boldText(defn.text)}\n\n`;
          }
        });
        return messageParts;
      }

      template.components.forEach((component: any) => {
        switch (component.type) {
          case 'header':
            const headerTemplate = templateDefinition.components.find(
              (comp: any) => comp.type === 'HEADER'
            );
            if (headerTemplate && headerTemplate.text) {
              const formattedHeader = this.applyParameters(
                headerTemplate.text,
                component.parameters
              );
              messageParts.header = this.boldText(formattedHeader);
            }
            break;

          case 'body':
            const bodyTemplate = templateDefinition.components.find(
              (comp: any) => comp.type === 'BODY'
            );
            if (bodyTemplate && bodyTemplate.text) {
              const formattedBody = this.applyParameters(
                bodyTemplate.text,
                component.parameters
              );
              messageParts.body += this.boldText(formattedBody) + '\n\n';
            }
            break;

          //   case 'footer':
          //     const footerTemplate = templateDefinition.components.find(
          //       (comp: any) => comp.type === 'FOOTER'
          //     );
          //     if (footerTemplate && footerTemplate.text) {
          //       messageParts.footer = this.boldText(footerTemplate.text);
          //     }
          //     break;

          case 'button':
            const buttonTemplate = templateDefinition.components.find(
              (comp: any) => comp.type === 'BUTTONS'
            );
            if (buttonTemplate && buttonTemplate.buttons) {
              const button =
                buttonTemplate.buttons[parseInt(component.index || '0')];
              if (button) {
                let formattedUrl = button.url || '';
                component.parameters?.forEach((param: any) => {
                  formattedUrl = formattedUrl.replace('{{1}}', param.text);
                });
                messageParts.buttons.push({
                  text: button.text,
                  url: formattedUrl,
                });
              }
            }
            break;

          default:
            console.warn(`Unknown component type: ${component.type}`);
        }
      });

      return messageParts;
    } catch (error) {
      console.error('Error formatting template message:', error);
      return null;
    }
  }

  private formatInteractiveMessage(interactiveMessage: any): any {
    let messageParts: any = {
      body: '',
      buttons: [],
    };

    const sanitizedMessageData = this.sanitizeMessageData(interactiveMessage);

    try {
      const interactiveData = JSON.parse(sanitizedMessageData);

      if (interactiveData) {
        messageParts.body =
          this.boldText(
            interactiveData.interactive?.body?.text ||
              `${interactiveData.interactive?.list_reply?.title}<br/>${interactiveData.interactive?.list_reply?.description}`
          ) || '';

        const buttonLabel = interactiveData.interactive.action?.button || '';
        const sections = interactiveData.interactive.action?.sections || [];

        sections.forEach((section: any) => {
          section.rows.forEach((row: any) => {
            messageParts.buttons.push({
              text: row.title,
              description: row.description,
            });
          });
        });

        messageParts.body += `<br/><br/>${buttonLabel}`;
        return messageParts;
      }
    } catch (error) {
      console.error('Failed to parse message_data:', error);
    }

    return messageParts;
  }

  private formatTextMessage(message_data: string): string {
    try {
      // replacing newline characters with HTML breaks
      const cleanedData = this.sanitizeMessageData(message_data);

      const parsedData = JSON.parse(cleanedData);

      return this.boldText(
        parsedData?.text?.body
          ? this.sanitizeMessageData(parsedData.text.body)
          : ''
      );
    } catch (error) {
      console.error('Failed to parse message_data:', error);
      return '';
    }
  }

  private applyParameters(text: string, parameters: any[]): string {
    let formattedText = text;
    parameters.forEach((param: any, index: number) => {
      formattedText = formattedText.replace(`{{${index + 1}}}`, param.text);
    });
    return formattedText;
  }

  public getOriginalMessage(originalMessageId: string): any {
    for (const group of this.selectedChatConversations?.chats) {
      const originalMessage = group.messages.find(
        (msg: any) => msg.message_id === originalMessageId
      );

      if (originalMessage) {
        const messageBody = originalMessage?.body
          ? this.boldText(originalMessage.body.substring(0, 250)) +
            (originalMessage.body?.length > 250 ? '.....' : '')
          : `<i class="pi ${this.getFileIconClass(
              originalMessage.type
            )}"></i> ${this.toTitleCase(originalMessage?.type)}`;
        return messageBody;
      }
    }
    return null;
  }

  public boldText(text: string): string {
    return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  }

  private formatUnixTimestamp(
    unixTimestamp: number,
    formatType: 'standard' | 'custom' = 'standard'
  ): string {
    const date = new Date(unixTimestamp * 1000);

    const utcDate = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );

    if (formatType === 'custom') {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(utcDate);
    } else {
      return utcDate.toLocaleString('en-US');
    }
  }

  public async openChat(chat: any) {
    // if (this.fetchInterval) {
    //   clearInterval(this.fetchInterval);
    // }

    if (chat?.mobile_number === this.selectedChat?.mobile_number) {
      return;
    }

    this.isChatLoading = true;
    this.replyContext = null;
    this.selectedChat = chat;
    await this.updateCurrentConversation();
    // this.selectedChatConversations = {
    //   chats: this.formatForChatView(this.selectedChat?.chats),
    //   profile_picture: this.selectedChat?.profile_picture,
    // };

    this.isChatLoading = false;
    this.cdr.detectChanges();
    this.scrollToBottom();
    // this.fetchInterval = setInterval(async () => {
    //   await this.updateCurrentConversation();
    // }, 15000);
  }

  public openChatDetails(chat: any) {
    this.selectedChatDetail = {
      chat: {
        ...chat,
        ...(chat?.data ? { data: JSON.parse(chat?.data) } : {}),
      },
      broadcast: this.broadcastInfo,
    };
    this.showSidebar = true;
  }

  getLabel(type: string): string {
    switch (type) {
      case 'drives':
        return 'Drive';
      case 'degree_spec':
        return 'Degree, Specialization and Passout Year';
      case 'passout_year':
        return 'Passout Year';
    }
    return 'Type';
  }

  getValue(obj: any): string {
    switch (obj?.type) {
      case 'drives':
        return obj?.drive_name;
      case 'degree_spec':
        return `${obj?.degree_spec_name}, ${obj?.data?.passout_year}`;
      case 'passout_year':
        return obj?.passout_year;
    }
    return '';
  }

  getProfileImage(): string {
    if (this.chatType === 'broadcast' || (this.chatType === 'all' && this.selectedChat?.broadcast_id)) {
      return broadcastProfileIcon;
    }
    if (this.chatType === 'chats' || this.chatType === 'all') {
      return this.selectedChat?.profile_picture || chatProfileIcon;
    }
    return chatProfileIcon;
  }  

  public clearInputs() {
    this.showSidebar = false;
    this.selectedChatDetail = {};
  }

  public async openBroadcast(chat: any) {
    this.isChatLoading = true;
    this.replyContext = null;
    this.selectedChat = chat;

    let broadcastInfo: any = await this.chatService.fetchBroadcastInfo({
      type: chat?.type,
      data: JSON.parse(chat?.data),
      group_account_id: this.group_account_id,
      broadcast_id: chat?.broadcast_id,
    });

    this.broadcastInfo = broadcastInfo?.studentData;

    this.selectedChatConversations = {
      chats: this.formatForChatView(broadcastInfo?.chats),
      profile_picture: broadcastProfileIcon,
      type: 'broadcast'
    };

    this.isChatLoading = false;
    this.cdr.detectChanges();
    this.scrollToBottom();
  }

  private async updateCurrentConversation() {
    if (!this.selectedChat) return;

    let res: any = await this.chatService.fetchChats({
      group_account_id: this.group_account_id,
      mobile_number: this.selectedChat.mobile_number,
      type: 'chats'
    });

    let formattedChat = this.processFetchedChats(res?.data);
    this.selectedChatConversations = {
      chats: this.formatForChatView(formattedChat[0]?.chats),
      profile_picture: this.selectedChat?.profile_picture,
      type:'chat'
    };

    this.cdr.detectChanges();
    this.scrollToBottom();
  }

  private formatForChatView(chats: any[]): any[] {
    const groupedChats: any[] = [];

    const sortedChats = chats.sort((a, b) => {
      const aTimestamp = a.received_timestamp || a.delivered_timestamp;
      const bTimestamp = b.received_timestamp || b.delivered_timestamp;
      return parseInt(aTimestamp) - parseInt(bTimestamp);
    });

    sortedChats.forEach((chat) => {
      const formattedMessage = this.formatMessage(chat);

      const date = formattedMessage.lastTimestamp.date;

      const dateGroup = groupedChats.find((group) => group.date === date);
      if (dateGroup) {
        dateGroup.messages.push(formattedMessage);
      } else {
        groupedChats.push({
          date,
          messages: [formattedMessage],
        });
      }
    });

    return groupedChats;
  }

  async searchValueChange(event: any) {
    if (this.isChatScrolling || this.isBroadcastScrolling || this.isAllScrolling) {
      return;
    }
  
    try {
      this.searchValue = event.target.value;
      this.hasMoreData = true;
      this.selectedChat = {};
      this.selectedChatConversations = [];
      this.currentBroadcastScroll = 0;
      this.currentChatScroll = 0;
      this.currentAllScroll = 0;
      this.isChangeDisabled = true;
      this.updateTabDisability();
  
      if (this.chatType === 'chats') {
        this.chatList = [];
        this.isChatScrolling = true;
  
        if (!this.searchValue?.length) {
          this.currentChatScroll++;
        }
  
        try {
          const fetchedChats: any = await this.chatService.fetchChats({
            group_account_id: this.group_account_id,
            offset: 0,
            ...(this.searchValue?.length ? { search: this.searchValue } : {}),
            limit: this.limit,
            type: 'chats',
          });
  
          this.chatList = this.sortArrayByTimestamp(
            this.processFetchedChats(fetchedChats?.data),
            'lastTimestamp',
            'desc'
          );
        } catch (error) {
          console.error('Error fetching chats:', error);
          this.chatList = [];
        } finally {
          this.isChatScrolling = false;
        }
      } else if (this.chatType === 'broadcast') {
        this.broadcastList = [];
        this.isBroadcastScrolling = true;
  
        if (!this.searchValue?.length) {
          this.currentBroadcastScroll++;
        }
  
        try {
          const res: any = await this.chatService.fetchChats({
            group_account_id: this.group_account_id,
            search: this.searchValue,
            offset: 0,
            limit: this.limit,
            type: 'broadcasts',
          });
  
          this.broadcastList = this.sortArrayByTimestamp(
            this.processFetchedChats(res),
            'lastTimestamp',
            'desc'
          );
        } catch (error) {
          console.error('Error fetching broadcasts:', error);
          this.broadcastList = [];
        } finally {
          this.isBroadcastScrolling = false;
        }
      } else {
        this.allChatList = [];
        this.isAllScrolling = true;
  
        if (!this.searchValue?.length) {
          this.currentAllScroll++;
        }
  
        try {
          const res: any = await this.chatService.fetchChats({
            group_account_id: this.group_account_id,
            search: this.searchValue,
            offset: 0,
            limit: this.limit,
            type: 'all',
          });
  
          this.allChatList = this.sortArrayByTimestamp(
            this.processFetchedChats([...res?.broadcasts, ...res?.chats]),
            'lastTimestamp',
            'desc'
          );
        } catch (error) {
          console.error('Error fetching all chats:', error);
          this.allChatList = [];
        } finally {
          this.isAllScrolling = false;
        }
      }
    } catch (error) {
      console.error('Error in searchValueChange:', error);
    } finally {
      this.isChangeDisabled = false;
      this.updateTabDisability();
    }
  }

  async chatTypeChange(event: any) {
    if (this.isChangeDisabled) {
      return;
    }

    // disabling tab changes
    this.isChangeDisabled = true;
    this.updateTabDisability();

    try {
      this.chatType = event.value;

      this.resetStates();

      if (this.chatType === 'chats') {
        await this.fetchChats();
      } else if (this.chatType === 'broadcast') {
        await this.fetchBroadcasts();
      } else {
        await this.fetchAllCommunication();
      }
    } catch (error) {
      console.error('An error occurred while changing the chat type:', error);
    } finally {
      this.isChangeDisabled = false;
      this.updateTabDisability();
    }
  }

  resetStates() {
    this.isAllScrolling = false;
    this.isChatScrolling = false;
    this.isBroadcastScrolling = false;
    this.selectedChat = {};
    this.selectedChatConversations = {};
    this.hasMoreData = true;
    this.currentBroadcastScroll = 0;
    this.currentChatScroll = 0;
    this.currentAllScroll = 0;
    this.chatList = [];
    this.allChatList = [];
    this.broadcastList = [];
    this.broadcastInfo = [];
    this.searchPlaceholders =
      this.chatType === 'broadcast'
        ? 'Broadcast Name'
        : this.chatType === 'all'
        ? 'Broadcast Name, Email, Registration No'
        : 'Email, Registration No';
    this.openNewBroadcast = false;
    this.searchValue = '';
  }

  updateTabDisability() {
    this.chatTypeOptions = this.chatTypeOptions.map(option => ({
      ...option,
      disabled: this.isChangeDisabled
    }));
  }
  
  newBroadcast() {
    this.openNewBroadcast = true;
  }

  async confirmBroadcast() {
    this.isLoading = true;
    const payload: any = {
      name: this.broadcastName,
      type: this.broadcastBy,
      data: {
        [this.broadcastBy]: this.selectedBroadcastType,
        ...(this.broadcastBy === 'degree_spec'
          ? { passout_year: this.selectedPassoutYear }
          : {}),
      },
      group_account_id: this.group_account_id
    };
    let res: any = await this.chatService.createBroadcasts(payload);
    if (res) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Broadcast created successfully.',
      });
      this.broadcastList = [];
      this.openNewBroadcast = false;
      await this.fetchBroadcasts(true);
    } else {
      this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.WARNING,
        detail: 'Error creating broadcasts, Try again later.',
      });
    }
    this.isLoading = false;
  }

  async onBroadcastTypeChange() {
    this.isLoading = true;

    switch (this.broadcastBy) {
      case 'drives':
        const drivePayload = {
          group_account_id: this.group_account_id,
          search: '',
          limit: 10,
          page: 1,
          sort_by: 'lastDate',
          sort_order: 1,
          fields: ['name', 'drive_id'],
          filters: {
            drive_status: ['Upcoming'],
            campus: [],
            degree: [],
            department: [],
            pass_out_year: [],
            createdAt: [],
          },
        };
        let driveRes: any = await this.chatService.getDrives(drivePayload);

        this.broadcastCreateOptions = driveRes?.data?.data?.map(
          (drive: any) => ({
            label: drive?.name,
            value: drive?.drive_id,
          })
        );
        break;

      case 'degree_spec':
        let res: any = await this.globalService.fetchCampusDetails(
          this.group_account_id
        );

        this.broadcastCreateOptions = res?.data?.map((data: any) => ({
          label: `${data?.degree_name} - ${data?.specialization}`,
          value: `${data?.degree_id}_${data?.programme_id}`,
        }));
        break;

      case 'passout_year':
        this.broadcastCreateOptions = this.passoutYearsOptions;
        break;

      default:
        this.broadcastCreateOptions = [];
        break;
    }

    this.isLoading = false;
  }

  getDropdownLabel(broadcastType: string): string {
    switch (broadcastType) {
      case 'drives':
        return 'Select Drive';
      case 'degree_spec':
        return 'Select Degree and Pass out Year';
      case 'passout_year':
        return 'Select Passout Year';
      default:
        return 'Select Option';
    }
  }

  onBroadcastDialogClose() {
    this.broadcastName = '';
    this.broadcastBy = '';
    this.selectedBroadcastType = '';
    this.broadcastCreateOptions = [];
  }

  isBroadcastValid(): boolean {
    if (this.broadcastBy && this.broadcastName && this.selectedBroadcastType) {
      if (this.broadcastBy === 'degree_spec' && !this.selectedPassoutYear) {
        return true;
      }
      return false;
    }
    return true;
  }

  formatSingleMessage(message: string, recent_message?: any): SafeHtml {
    const type = recent_message?.type;
    const maxLength = 20;
    const formattedMessage =
      message && message.length > maxLength && (type && !this.fileTypes.includes(type?.toLowerCase()))
        ? message.substring(0, maxLength) + '...'
        : message || '--';

    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0;
  }

  toggleFileOptions() {
    this.showFileOptions = !this.showFileOptions;
  }

  selectFileType(type: string) {
    this.showFileOptions = false;
    this.selectedFileType = type;

    switch (type) {
      case 'image':
        this.imageInput.nativeElement.click();
        break;
      case 'video':
        this.videoInput.nativeElement.click();
        break;
      case 'document':
        this.documentInput.nativeElement.click();
        break;
    }
  }

  async sendMessage() {
    if ((!this.messageText.trim() && this.filePreviews.length === 0) || this.sendingMessage) return;

    this.sendingMessage = true;
    let fileUrls: string[] = [];

    for (const preview of this.filePreviews) {
      this.isFileUploading = true;
      const filePath = await this.uploadFileForWhatsAppMessage(
        preview.file,
        preview.fileType
      );
      if (filePath) {
        fileUrls.push(filePath);
      }
      this.isFileUploading = false;
    }

    const messagePayload: any = {
      group_account_id: this.group_account_id,
      userData: [
        {
          user_id: this.selectedChat?.user_id,
          mobile_number: this.selectedChat?.mobile_number,
          mobile_no_verified: this.selectedChat?.mobile_no_verified,
        },
      ],
      template_content: this.messageText,
      context: {
        message_id: this.replyContext,
      },
      custom_chat: true,
    };

    if (fileUrls.length > 0) {
      messagePayload.media_url = `${this.globalService.prefix}/${this.globalService.publicBucket}/${fileUrls[0]}`;
      messagePayload.media_type = this.selectedFileType;
      messagePayload.media_filename = this.filePreviews[0]?.fileName;
    }

    const response: any = await this.globalService.sendWhatsappNotification(
      messagePayload
    );

    if (response?.success) {
      const newMessage = this.createMessageObject(messagePayload, 'delivered');
      this.selectedFileType = '';
      if (this.isMultiline) this.isMultiline = !this.isMultiline;
      this.updateToCurrentConversation(newMessage);
      this.replyContext = null;
      this.cdr.detectChanges();
      this.scrollToBottom();
    }

    this.messageText = '';
    this.filePreviews = [];
    this.sendingMessage = false;
  }

  createMessageObject(payload: any, direction: string, type?: any) {
    const now = new Date();
    type = type ?? (payload?.media_type ? payload?.media_type : 'text');
    return {
      ...(payload?.media_url ? {
        media_url: payload?.media_url,
        fileName: this.getFileNameFromUrl(payload?.media_url)
      } : {}),
      ...(payload?.media_type ? { type: payload?.media_type } : {}),
      header: '',
      body: payload?.template_content,
      buttons: [],
      lastTimestamp: {
        date: now.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        }),
        time: now.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
      },
      direction,
      type,
      message_id: '',
      ...(this.replyContext?.length
        ? { context: { message_id: this.replyContext } }
        : {}),
    };
  }

  toggleMultiline() {
    this.isMultiline = !this.isMultiline;
  }

  handleKeyDown(event: KeyboardEvent, inputType: 'single' | 'multiline') {
    if (event.key === 'Enter') {
      if (inputType === 'single') {
        event.preventDefault();
        this.sendMessage();
      } else if (inputType === 'multiline') {
        if (event.shiftKey || !event.shiftKey) {
          event.preventDefault();
          this.messageText += '\n';
        }
      }
    }
  }

  autoResize(textarea: HTMLTextAreaElement) {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  setReplyContext(messageId: string) {
    this.replyContext = messageId;
    this.showMessageOptions = '';
  }

  clearReplyContext() {
    this.replyContext = null;
  }

  toggleMessageOptions(messageId: string) {
    this.showMessageOptions =
      this.showMessageOptions === messageId ? null : messageId;
  }

  sanitizeMessageData(message: string) {
    return message
      .replace(/\\n/g, '</br>')
      .replace(/\\r/g, '')
      .replace(/\n/g, '</br>')
      .replace(/\r/g, '');
  }

  private async uploadFileForWhatsAppMessage(
    file: File,
    fileType: string
  ): Promise<string | false> {
    try {
      const type: string = file.type;
      const blobData: Blob = new Blob([file], { type });
      const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
      const cleanFileName = file.name.replace(/\s+/g, '_');

      const pay = {
        bucketName: this.globalService.publicBucket,
        fileName: `whatsapp_files/${this.group_account_id}/${this.selectedChat?.user_id}/${timestamp}_${cleanFileName}`,
        type: type,
      };

      const s3SignedUrl = await this.globalService.getSignedUploadUrl(pay);

      const s3Response: any = await this.fileUploadService.uploadUsingSignedUrl(
        s3SignedUrl.data.response,
        blobData
      );

      if (s3Response && s3Response.status === 200) {
        return pay.fileName;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  }

  handleFileUpload(event: Event, fileType: string) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const maxFileSize = this.getMaxFileSize(fileType);
      if (file.size > maxFileSize) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Too Large',
          detail: `The selected file exceeds the maximum size of ${this.docMaxSize[fileType]}. Please select a smaller file.`
        })
        return;
      }

      this.selectedFile = file;
      this.selectedFileType = fileType;
      this.filePreviews.push({
        file,
        fileType,
        fileName: file.name.replaceAll(' ', '_'),
      });
    }
  }
  
  getMaxFileSize(fileType: string): number {
    switch (fileType) {
      case 'document':
        return 100 * 1024 * 1024; // 100 MB
      case 'image':
        return 5 * 1024 * 1024; // 5 MB
      case 'video':
        return 16 * 1024 * 1024; // 16 MB
      default:
        return 5 * 1024 * 1024;
    }
  }

  removeSelectedFile() {
    this.selectedFile = null;
    this.selectedFileType = null;
  }

  removeFilePreview(preview: {
    file: File;
    fileType: string;
    fileName: string;
  }) {
    this.filePreviews = this.filePreviews.filter((p) => p !== preview);
  }

  getFileIconClass(fileType: string): string {
    switch (fileType) {
      case 'image':
        return 'pi pi-image';
      case 'video':
        return 'pi pi-video';
      case 'document':
        return 'pi pi-file';
      default:
        return 'pi pi-file';
    }
  }

  isMediaType(type: string): boolean {
    return this.fileTypes.includes(type?.toLowerCase());
  }

  getFileNameFromUrl(url: string): string {
    return (
      url.split('/').pop()?.replace('_', '$#$').split('$#$')[1] || 'download'
    );
  }

  truncateFileName(fileName: string, limit: number): string {
    return fileName.length > limit
      ? `${fileName.slice(0, limit)}...`
      : fileName;
  }

  downloadFile(message: any): void {
    if (message?.direction === 'received') {
      this.downloadUserFile(message?.media_url);
    }
    else {
      const { media_url: url } = message;
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = this.getFileNameFromUrl(url);
          link.click();
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => console.error('Download failed:', error));
    }
  }

  async downloadUserFile(mediaId: string): Promise<void> {
    try {
      const { blob, contentType } = await this.chatService.getUserSentFile(mediaId);
      const fileBlob = new Blob([blob?.data || blob], { type: contentType });  
      const extensionMap: { [key: string]: string } = {
        'application/pdf': '.pdf',
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'text/plain': '.txt',
        'application/json': '.json',
        'application/msword': '.doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/excel': '.xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'audio/mpeg': '.mp3',
        'video/mp4': '.mp4',
        'application/zip': '.zip'
      };
  
      const extension = extensionMap[contentType] || '.bin';
      const filename = `file_${mediaId}${extension}`;
  
      const downloadURL = URL.createObjectURL(fileBlob);
      const anchor = document.createElement('a');
      anchor.href = downloadURL;
      anchor.download = filename;
      
      document.body.appendChild(anchor);
      anchor.click();      
      document.body.removeChild(anchor);
      URL.revokeObjectURL(downloadURL);
  
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

  toTitleCase(text: string): string {
    return text
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  showDialog(buttonElement: any) {
    const rect = buttonElement.nativeElement.getBoundingClientRect();

    this.dialogStyle = {
      width: '35vw',
      position: 'fixed',
      top: `${rect.top - 220}px`,
      left: `${rect.left}px`,
    };

    this.showTemplateDialog = true;
  }

  selectTemplate(template: any) {
    this.selectedTemplate = template;
    this.placeholders = this.getPlaceholders(
      template.components.find((c: any) => c.type === 'BODY')?.text || ''
    ).map(() => '');
  }

  getPlaceholders(text: string): string[] {
    const regex = /{{\d+}}/g;
    return text.match(regex) || [];
  }

  hasPlaceholders(text: string): boolean {
    return /{{\d+}}/.test(text);
  }

  getPreview(template: any): string {
    let previewText = '';

    template.components.forEach((component: any) => {
      let componentText = '';

      if (component?.type !== 'FOOTER') {
        componentText = component.text || '';
      }

      const placeholders =
        component.type === 'HEADER'
          ? this.headerPlaceholders
          : component.type === 'BODY'
          ? this.bodyPlaceholders
          : component.type === 'BUTTON'
          ? this.buttonPlaceholders
          : [];

      placeholders.forEach((value: any, index: any) => {
        componentText = componentText.replace(
          `{{${index + 1}}}`,
          value || `{{${index + 1}}}`
        );
      });

      previewText += `<div>${this.boldText(componentText)}</div>`;
    });

    return previewText;
  }

  async sendTemplateMessage(template: any, overlayPanelRef: any) {
    if (!this.validateTemplatePlaceholders()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill in all required placeholders.',
      });
      return;
    }

    this.isLoading = true;
    const payload = {
      type: this.selectedChat?.broadcast_id ? 'broadcast' : 'chats',
      templateName: template.name,
      headerPlaceholders: this.headerPlaceholders,
      buttonPlaceholders: this.buttonPlaceholders,
      bodyPlaceholders: this.bodyPlaceholders,
      group_account_id: this.group_account_id,
      userData: {
        mobile_number: [this.selectedChat?.mobile_number],
        user_id: this.selectedChat?.user_id,
      },
      broadcast_id: this.selectedChat?.broadcast_id,
      broadcast_type: this.selectedChat?.type,
      broadcast_data: this.selectedChat?.data
        ? JSON.parse(this.selectedChat?.data)
        : {},
    };

    try {
      const res: any = await this.chatService.sendTemplateMessage(payload);
      let messagePayload: any;

      if (res) {
        overlayPanelRef.hide();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Message sent successfully.',
        });

        if (this.chatType === 'chats') {
          messagePayload = {
            group_account_id: this.group_account_id,
            userData: [
              {
                user_id: this.selectedChat?.user_id,
                mobile_number: this.selectedChat?.mobile_number,
                mobile_no_verified: this.selectedChat?.mobile_no_verified,
              },
            ],
            template_content: this.getPreview(this.selectedTemplate),
            context: {
              message_id: this.replyContext,
            },
            custom_chat: true,
          };
        } else {
          messagePayload = {
            group_account_id: this.group_account_id,
            template_content: this.getPreview(this.selectedTemplate),
            broadcast_id: this.selectedChat?.broadcast_id,
            custom_chat: true,
          };
        }

        const newMessage = this.createMessageObject(
          messagePayload,
          'delivered',
          'Template'
        );

        this.updateToCurrentConversation(newMessage);
        this.cdr.detectChanges();
        this.scrollToBottom();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to send message.',
        });
      }
    } catch (error) {
      console.error('Error sending template message:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred while sending the message.',
      });
    }
    finally {
      this.isLoading = false;
    }

    this.selectedTemplate = null;
    this.headerPlaceholders = [];
    this.buttonPlaceholders = [];
    this.bodyPlaceholders = [];
  }

  updateToCurrentConversation(newMessage: any) {
    if (this.selectedChatConversations?.chats?.length) {
      this.selectedChatConversations?.chats?.[
        this.selectedChatConversations.chats.length - 1
      ]?.messages?.push(newMessage);
    } else {
      this.selectedChatConversations?.chats.push({
        date: new Date().toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        }),
        messages: [newMessage],
      });
    }

    this.chatList = this.chatList.map((chat: any) => {
      if (chat?.mobile_number === this.selectedChat?.mobile_number) {
        let lastMessage = newMessage?.header || newMessage?.body;

        if (!lastMessage) {
          if (this.fileTypes.includes(newMessage?.type)) {
            lastMessage = `<i class="pi ${this.getFileIconClass(
              newMessage.type
            )}"></i> ${this.toTitleCase(newMessage?.type)}`;
          } else {
            lastMessage = '(No Content)';
          }
        }

        chat.lastMessage =
          lastMessage.length > 45
            ? lastMessage.slice(0, 45) + '...'
            : lastMessage;
      }
      return chat;
    });
  }

  validateTemplatePlaceholders(): boolean {
    const requiredHeaderPlaceholders =
      this.selectedTemplate?.components
        .find((c: any) => c.type === 'HEADER')
        ?.text?.match(/{{\d+}}/g) || [];
    const requiredBodyPlaceholders =
      this.selectedTemplate?.components
        .find((c: any) => c.type === 'BODY')
        ?.text?.match(/{{\d+}}/g) || [];
    const requiredButtonPlaceholders =
      this.selectedTemplate?.components
        .find((c: any) => c.type === 'BUTTON')
        ?.text?.match(/{{\d+}}/g) || [];

    const isHeaderComplete =
      requiredHeaderPlaceholders.length ===
      this.headerPlaceholders.filter(Boolean).length;
    const isBodyComplete =
      requiredBodyPlaceholders.length ===
      this.bodyPlaceholders.filter(Boolean).length;
    const isButtonComplete =
      requiredButtonPlaceholders.length ===
      this.buttonPlaceholders.filter(Boolean).length;

    if (!isHeaderComplete || !isBodyComplete || !isButtonComplete) {
      console.warn('Incomplete placeholders detected');
      return false;
    }

    return true;
  }

  clearSelectedTemplate() {
    this.selectedTemplate = null;
    this.placeholders = [];
  }

  boldAndColorText(text: string): SafeHtml {
    const formattedText = text
      .replace(
        /\{\{(.*?)\}\}/g,
        '<span style="color: #007ad9;">{{ $1 }}</span>'
      )
      .replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }

  updateBreadCrumb() {
    const breadcrumbs = [{ label: Breadcrumb.CHAT, url: '' }];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  get shouldShowFloatingTemplateArea(): boolean {
    const hasSelectedChat = !this.isEmptyObject(this.selectedChat);
    const hasSelectedChatConversations = !this.isEmptyObject(
      this.selectedChatConversations
    );
    const isInactiveChat = this.selectedChat && !this.selectedChat.isActive;
    const isBroadcastChat = this.chatType === 'broadcast';

    return (
      hasSelectedChat &&
      ((isInactiveChat && hasSelectedChatConversations) || isBroadcastChat)
    );
  }

  routeToSettings() {
    this.router.navigate(['/settings']);
  }

  reloadUI() {
    // this.cdr.detectChanges();
    window.location.reload();
  }

  ngOnDestroy() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }
}
