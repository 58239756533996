import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DateStatus,
  PatDriveStatus,
  Severity,
  Summary,
  Message,
  RoundTypes,
  ElBtnProperties,
  PassoutYearsOptions,
  roundColors,
  SBtnProperties
} from '@enum';
import { btnProperties } from '@models';
import { DrivePlannerService, DriveService, GlobalService } from '@services';
import { ConfirmationService, MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { trashIcon, editIcon, errorIcon } from '@assets';
import { TableComponent } from 'src/app/shared/components/table/table.component';
import { RoundsService } from 'src/app/core/services/rounds.service';


interface IRoundData {
  event_id: string;
  end_date: string;
  start_date: string;
  event_name: string;
  round_type: string;
  date_status: string;
  order_id: number;
  drive_id: string;
  group_account_id: string;
  bg_color: string;
  event_type: string;
};

type FilterKey = 'campus' | 'passOutYear' | 'department' | 'degreeSpec';

interface FilterOption {
  filterName: string;
  key: FilterKey;
  array: any[];
}

interface IErrorData {
  data: string;
  message: string;
}

@Component({
  selector: 'app-drive-round-creation',
  templateUrl: './drive-round-creation.component.html',
  styleUrls: ['./drive-round-creation.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class DriveRoundCreationComponent implements OnInit {
  @Input() driveData: any = {};
  @Input() driveStatus: string = '';
  @ViewChild('tableContainer') tableContainer!: ElementRef;
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  accountData: any = localStorage.getItem('account_details');
  userData: any = localStorage.getItem('user_details');
  accountMetaData: any = localStorage.getItem('account_metadata');
  group_account_id: string;
  roundTypes = RoundTypes;
  dateStatus = DateStatus;
  errorIcon = errorIcon;
  roundColors = roundColors;
  editIcon = editIcon;
  trashIcon = trashIcon;
  roundData: IRoundData[] = [];
  btnProperties = ElBtnProperties;
  SBtnProperties: { [key: string]: btnProperties } = SBtnProperties;
  filterData: FilterOption[] = [
    {
      filterName: "Campus",
      key: 'campus',
      array: []
    },
    {
      filterName: "Pass Out Year",
      key: 'passOutYear',
      array: PassoutYearsOptions
    },
    {
      filterName: "Department",
      key: 'department',
      array: []
    },
    {
      filterName: "Degree",
      key: 'degreeSpec',
      array: []
    }
  ];
  selectedFilters: any = {
    passOutYear: [],
    campus: [],
    department: [],
    degreeSpec: [],
    studentStatus: []
  };
  fieldMaps: any = {};
  dataCount: number = 0;
  captionsList: any = { actions: false, columns: true, download: true, filter: false, search: true, pagination: true, header: true, limit: true, checkbox: true, manual_actions: true, custom_sort: true, sendEmail: false }
  pageLimits: any[] = [{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }, { label: 100, value: 100 }];
  tableHeight: string = '63vh'
  totalPage: number = 0;
  campusDetails: any[] = [];
  filteredDepartments: any[] = [];
  searchValue: string = '';
  dataList: any[] = [];
  isLoading: boolean = false;
  columns: any[] = [];
  selectedPage: number = 1;
  selectedOptions: any[] = [];
  rowsize: number = 10;
  sort_by = 'primary_email';
  sort_order = 1;
  showRoundCreationSidebar: boolean = false;
  roundCreationForm: FormGroup;
  currentDate = new Date();
  selectedRound: any | null = null;
  formType: 'Add' | 'Edit' = 'Add';
  roundTypesLabel = this.roundTypes.reduce((acc, each) => { acc[each.value] = each.label; return acc; }, {} as any);
  dateStatusLabel = this.dateStatus.reduce((acc, each) => { acc[each.value] = each.label; return acc; }, {} as any);
  showStudentActionSidebar: boolean = false;
  sidebarPurpose: 'addStudents' | 'removeStudents' = 'addStudents';
  selectedCategory = 'Email'
  categories = ['Email', 'Registration Number']
  emailOrReg: any[] = []
  errorData: IErrorData[] = [];
  errorDataToDisplay: IErrorData[] = [];
  drive_id: string = '';
  tableLoading: boolean = false;
  studentCountOnEvent: any = {};
  search: string = 'Email or Registration Number';
  downloadCategories: any = [
    { header: 'Basic info', data: [], isSelectedAll: false },
    { header: 'Academic info', data: [], isSelectedAll: false },
    { header: 'Additional info', data: [], isSelectedAll: false },
    { header: 'Download Resume', data: [], isSelectedAll: false, type: 'resume' },
    { header: 'Optional Form', data: [], isSelectedAll: false },
  ];
  showDownData: boolean = false;
  downloadColumnAs = 'excel';
  salary_label: string = 'Salary';
  hoveredEvent: string | null = null;
  tableColumns = [
    { field: 'data', header: `Student Data`, width: '50%' },
    { field: 'message', header: `Error Message`, width: '50%' },
  ];
  currentPageErrorTable = 1;
  totalPageErrorTable = 0;

  constructor(
    public globalService: GlobalService,
    private roundService: RoundsService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private drivePlannerService: DrivePlannerService,
    private driveService: DriveService,
    private confirmationService: ConfirmationService,
  ) {
    this.formatLabelData();
    this.accountData = JSON.parse(this.accountData);
    this.userData = JSON.parse(this.userData);
    this.group_account_id = this.accountData.group_account_id;
    let fields = this.globalService.formatFields(this.accountMetaData.field_labeling);
    const { academic_info, basic_info, additional_info } = fields;
    this.fieldMaps = { ...academic_info, ...basic_info, ...additional_info };

    this.roundCreationForm = this.fb.group({
      start_date: [this.currentDate, Validators.required],
      end_date: [this.currentDate, Validators.required],
      date_status: [null, Validators.required],
      event_id: [null],
      order_id: [0],
      round_type: [null, Validators.required],
    });
  };

  formatLabelData() {
    this.globalService.driveLabelSubject.subscribe((label) => {
      const accountMetaData = localStorage.getItem('account_metadata');
      this.accountMetaData = JSON.parse(accountMetaData ?? '{}');
      const fields = this.globalService.formatFields(this.accountMetaData.field_labeling);

      const { academic_info, basic_info, additional_info } = fields;
      this.fieldMaps = { ...academic_info, ...basic_info, ...additional_info };

      const { salary_label } = label.labeling;
      this.salary_label = salary_label;
      this.search = `${this.fieldMaps['Email']} or ${this.fieldMaps['Registration Number']}`;

      this.columns = [
        { field: 'primary_email', header: this.fieldMaps['Email'] ?? 'Emails', freezable: true, sortable: true, tableDefault: true, width: '15vw' },
        { field: 'registration_number', header: this.fieldMaps['Registration Number'] ?? 'Registration Number', freezable: false, sortable: true, tableDefault: true, width: '12vw' },
        { field: 'first_name', header: 'Name', freezable: false, sortable: true, tableDefault: true, width: '12vw', combineCols: 'last_name', type: 'combine' },
        { field: 'full_name', header: 'Campus', freezable: false, sortable: true, tableDefault: true, width: '14vw' },
        { field: 'passed_out_year', header: 'Pass Out Year', freezable: false, sortable: true, tableDefault: true, width: '12vw' },
        { field: 'department', header: 'Department', freezable: false, sortable: true, tableDefault: true, width: '15vw' },
        { field: 'specialization', header: 'Degree & Specialization', freezable: false, sortable: true, tableDefault: true, width: '20vw' },
        { field: 'specialization_minor', header: 'Specialization Minor', freezable: false, sortable: true, tableDefault: true, width: '13vw' }
      ];
    })
  }

  async ngOnInit() {
    this.isLoading = true;
    this.drive_id = this.driveData.drive_id;
    this.fetchCampusDetail();

    if (this.driveStatus === PatDriveStatus.saved) {
      this.captionsList = {
        actions: false,
        columns: true,
        download: false,
        filter: false,
        search: false,
        pagination: false,
        header: true,
        limit: false,
        checkbox: true,
        manual_actions: false,
        custom_sort: true,
        sendEmail: false
      }
    }

    await this.fetchRounds();
    await Promise.all([
      this.getCountOfEveryRound(),
      this.fetchStudentsInDriveRound()
    ]);

    this.isLoading = false;
  }

  async fetchCampusDetail() {
    const response = await this.driveService.fetchCampusDetails(this.group_account_id);
    if (response) {
      this.campusDetails = response;
      this.updateFilterData();
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ADMISSIONERROR,
      });
    }
  }

  async getCountOfEveryRound() {
    if(!this.roundData.length) return;

    const { drive_id, group_account_id } = this;
    const response = await this.driveService.getCountOfEveryRound({ drive_id, group_account_id });

    if(!response.success) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: response.message });
    };

    this.studentCountOnEvent = {};
    response.data.forEach((each: any) => {
      this.studentCountOnEvent[each.event_id] = each.count
    });
  };

  async fetchStudentsInDriveRound() {
    if (!this.roundData.length) return;
    const { drive_id, group_account_id } = this;

    const payload = {
      drive_id,
      group_account_id,
      page: this.selectedPage,
      limit: this.rowsize,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      search: this.searchValue,
      filters: this.getSelectedFilters(),
      event_id: this.selectedRound.event_id
    };

    this.tableLoading = true;
    const response = await this.driveService.fetchStudentsInDriveRound(payload);
    this.tableLoading = false;

    if(!response.success) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.ERRORFETCHINGEVENT });
    };

    this.dataList = response.data.data;
    this.dataCount = response.data.count;
    this.totalPage = Math.ceil(this.dataCount / this.rowsize);
  };

  onMouseEnter(event_id: string) {
    this.hoveredEvent = event_id;
  }

  onMouseLeave(event_id: string) {
    this.hoveredEvent = null;
  }

  async fetchRounds() {
    const response = await this.roundService.getDriveEvents({ drive_id: this.drive_id });

    if(!response.success) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: 'Error Fetching Rounds' });
    };

    this.roundData = response.data;
    this.selectedRound = this.roundData[0];
  };

  onSelectRound(round: IRoundData) {
    if(this.selectedRound === round) return;

    this.selectedRound = round;
    this.clearAllFilters();
  }

  private updateFilterData() {
    this.filterData[0].array = [];
    this.filterData[2].array = [];
    this.filterData[3].array = [];
    this.filteredDepartments = [];

    const uniqueItems = {
      campus: new Set<string>(),
      department: new Set<string>(),
      degree: new Set<string>()
    };

    const driveSelectedCampus = new Set(this.driveData.account_id);

    this.campusDetails.forEach((item) => {
      if (driveSelectedCampus.size === 0 || driveSelectedCampus.has(item.account_id)) {
        this.addUniqueItem(uniqueItems.campus, item.account_id, 0, item.full_name);
        this.addUniqueItem(uniqueItems.department, item.department_id, 2, item.department);
        this.addUniqueDegree(item, uniqueItems.degree);
      }
    });

    this.sortFilter([0, 2, 3]);
  };

  handleFilterChange(selectedFilter: string, event: any) {
    if (selectedFilter === 'campus') {
      this.filterData[2].array = [];
      this.filterData[3].array = [];
      this.selectedFilters['degreeSpec'] = [];
      this.selectedFilters['department'] = [];
      this.handleCampusFilter(event.value);
    } else if (selectedFilter === 'department') {
      this.filterData[3].array = [];
      this.selectedFilters['degreeSpec'] = [];
      this.handleDepartmentFilter(event.value);
    }
  };

  private handleCampusFilter(selectedCampuses: string[]) {
    const eventValueSet = new Set(selectedCampuses);

    this.filteredDepartments = this.campusDetails.filter(element =>
      eventValueSet.has(element.account_id)
    );
    const uniqueItems = {
      department: new Set<string>(),
      degree: new Set<string>()
    };

    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach((element: any) => {
      this.addUniqueItem(uniqueItems.department, element.department_id, 2, element.department);
      this.addUniqueDegree(element, uniqueItems.degree);
    });

    this.sortFilter([2, 3]);
  };

  private handleDepartmentFilter(selectedDepartments: any[]) {
    const eventValueSet = new Set(selectedDepartments);

    const degreeSet = new Set<string>();
    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach((element: any) => {
      if (eventValueSet.size == 0 || eventValueSet.has(element.department_id)) {
        this.addUniqueDegree(element, degreeSet);
      }
    });

    this.sortFilter([3]);
  };

  addUniqueItem(set: Set<string>, id: string, index: number, label: string) {
    if (!set.has(id)) {
      set.add(id);
      this.filterData[index].array.push({ label, value: id });
    }
  };

  private sortFilter(sortItems: number[]) {
    sortItems.forEach((each) => {
      this.filterData[each].array.sort((a: any, b: any) => a.label.localeCompare(b.label));
    })
  };

  private addUniqueDegree(item: any, degreeSet: Set<string>) {
    const key = `${item.degree_id}-${item.programme_id}`;
    if (!degreeSet.has(key)) {
      degreeSet.add(key);
      this.filterData[3].array.push({
        label: `${item.degree_name} - ${item.specialization}`,
        value: { degree_id: item.degree_id, programme_id: item.programme_id }
      });
    }
  };

  private getSelectedFilters() {
    const { campus: selectedCampusIds, department: selectedDepartmentIds, degreeSpec: selectedDegreeSpec } = this.selectedFilters;

    const selectedFilters = { ...this.selectedFilters };

    const selectedCampuses = new Set(selectedCampusIds);
    const selectedDepartments = new Set(selectedDepartmentIds);
    const selectedDegrees = new Set(selectedDegreeSpec.map((each: any) => `${each.degree_id}-${each.programme_id}`));

    const isCampusSelected = selectedCampuses.size > 0;
    const isDepartmentSelected = selectedDepartments.size > 0;
    selectedFilters.department = [];
    selectedFilters.degreeSpec = [];

    this.campusDetails.forEach(each => {
      const campusMatch = selectedCampuses.has(each.account_id);
      const departmentMatch = selectedDepartments.has(each.department_id);
      const degreeMatch = selectedDegrees.has(`${each.degree_id}-${each.programme_id}`);

      // Add to department if campus or department matches
      if ((!isCampusSelected && departmentMatch) || (campusMatch && departmentMatch)) {
        selectedFilters.department.push({
          account_id: each.account_id,
          department_id: each.department_id
        });
      }

      // Add to degreeSpec if the corresponding conditions match
      if (
        (isCampusSelected && isDepartmentSelected && campusMatch && departmentMatch && degreeMatch) ||
        (!isCampusSelected && isDepartmentSelected && departmentMatch && degreeMatch) ||
        (isCampusSelected && !isDepartmentSelected && campusMatch && degreeMatch) ||
        (!isCampusSelected && !isDepartmentSelected && degreeMatch)
      ) {
        selectedFilters.degreeSpec.push({
          account_id: each.account_id,
          department_id: each.department_id,
          degree_id: each.degree_id,
          programme_id: each.programme_id
        });
      }
    });

    return selectedFilters;
  };

  async clearAllFilters() {

    this.filterData[2].array = [];
    this.filterData[3].array = [];
    this.searchValue = '';
    this.filteredDepartments = [];

    this.selectedFilters = {
      passOutYear: [],
      campus: [],
      department: [],
      degreeSpec: [],
      studentStatus: []
    };

    this.selectedPage = 1;
    if (this.tableComponent) {
      this.tableComponent.searchValue = '';
      this.tableComponent.currentPage = 1;
      this.dataList = [];
    }

    this.updateFilterData();
    this.fetchStudentsInDriveRound();
  }

  applyFilter() {
    this.fetchStudentsInDriveRound();
  }

  tableOptionsEvent(event: any) {
    if (event === 'addStudents' || event === 'removeStudents') {
      this.showStudentActionSidebar = true;
      this.sidebarPurpose = event;
    };
  };

  searchChange(searchValue: string) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
  }

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
    this.fetchStudentsInDriveRound();
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    this.selectedPage = 1;
    this.fetchStudentsInDriveRound();
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage;
    this.fetchStudentsInDriveRound();
  }

  handleCheckboxStateChange(data: any) {
    this.selectedOptions = data.selectedOptions;
  }

  async sortChange(event: any) {
    this.sort_by = event.field;
    this.sort_order = event.order;
    this.fetchStudentsInDriveRound();
  }


  openRoundCreateSidebar() {
    this.showRoundCreationSidebar = true;
    this.formType = 'Add';
    this.roundCreationForm.patchValue({
      start_date: new Date(),
      end_date: new Date(),
      date_status: this.dateStatus[0].value,
      event_id: null,
      round_type: this.roundTypes[0].value,
    });
  }

  onCloseRoundCreation() {
    this.showRoundCreationSidebar = false;
  }

  checkDriveStatus() {
    if(this.driveStatus === PatDriveStatus.completed || this.driveStatus === PatDriveStatus.hold) {
      this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.DRIVECOMPLETERESTRICT });
      return false;
    }

    return true;
  }

  validateChanges() {
    const { start_date, end_date, date_status, round_type } = this.roundCreationForm.value;
    const oldStartDate = new Date(this.selectedRound?.start_date).getTime();
    const oldEndDate = new Date(this.selectedRound?.end_date).getTime();
    const isAddMode = this.formType === 'Add';

    if (!isAddMode && (start_date.getTime() === oldStartDate && end_date.getTime() === oldEndDate && date_status === this.selectedRound.date_status && round_type === this.selectedRound.round_type)) {
      this.messageService.add({ severity: Severity.WARN, summary: Summary.WARNING, detail: Message.EVENTNOCHANGES });
      return false;
    }

    return true;
  };

  async addRound() {
    if(!this.checkDriveStatus()) return;
    if(!this.validateChanges()) return;
    const { start_date, end_date, event_id, date_status, round_type, order_id } = this.roundCreationForm.value;
    const isAddMode = this.formType === 'Add';

    const newEvent: IRoundData = {
      event_id: isAddMode ? uuidv4() : event_id,
      group_account_id: this.group_account_id,
      date_status,
      start_date,
      end_date,
      event_name: this.driveData.name,
      bg_color: this.roundColors[round_type],
      round_type,
      order_id,
      drive_id: this.drive_id,
      event_type: 'round'
    };

    this.isLoading = true;
    const response = isAddMode ? await this.drivePlannerService.saveEvent(newEvent) : await this.drivePlannerService.updateEvent(newEvent);
    this.isLoading = false;

    const { data, success } = response.data;

    if (!success || !data) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: response.data.message || Message.EVENTSAVEERROR });
    };

    if(isAddMode) {
      newEvent.order_id = data.order_id;
      this.roundData.push(newEvent);
      this.selectedRound = newEvent;
      this.fetchStudentsInDriveRound();
    }
    else {
      const index = this.roundData.findIndex((each) => each.event_id === event_id);
      this.roundData[index] = newEvent;
    }

    this.onCloseRoundCreation();
  };

  deleteRound(round: IRoundData, event: any) {
    if (!this.checkDriveStatus()) return;
    event.stopPropagation();
    this.confirmationService.confirm({
      message: Message.DELETEEVENT,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      accept: async () => {

        const payload = {
          event_id: round.event_id,
          drive_id: this.drive_id,
          group_account_id: this.group_account_id,
          order_id: round.order_id
        }

        this.isLoading = true;
        const response = await this.drivePlannerService.deleteDriveEvents(payload);
        this.isLoading = false;

        if (!response.success) {
          return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: response.message });
        };

        const roundData: IRoundData[] = [];
        this.roundData.forEach((each) => {
          if(each.order_id !== round.order_id) {
            if(each.order_id > round.order_id) each.order_id -= 1;
            roundData.push(each);
          }
        });

        this.roundData = roundData;
        this.selectedRound = this.roundData[0];
        this.fetchStudentsInDriveRound();
        this.messageService.add({ severity: Severity.SUCCESS, summary: Summary.SUCCESS, detail: Message.ROUNDELETESUCCESS });
      }
    });
  };

  editRound(round: IRoundData, event: any) { 
    if (!this.checkDriveStatus()) return;
    event.stopPropagation();
    this.showRoundCreationSidebar = true;
    this.formType = 'Edit';
    this.selectedRound = round;
    this.roundCreationForm.patchValue({
      start_date: new Date(round.start_date),
      end_date: new Date(round.end_date),
      date_status: round.date_status,
      event_id: round.event_id,
      round_type: round.round_type,
      order_id: round.order_id,
    });
  }


  handleStartEndDateChange() {
    const start_date = new Date(this.roundCreationForm.value.start_date);
    const end_date = new Date(this.roundCreationForm.value.end_date);

    if (start_date.getTime() > end_date.getTime()) {
      this.roundCreationForm.patchValue({
        end_date: start_date
      });
    };
  };

  categoryChange() {
    this.emailOrReg = []
    this.errorData = []
    this.errorDataToDisplay = []
  }

  clearInputs() {
    this.showStudentActionSidebar = false;
    this.sidebarPurpose = 'addStudents';
    this.errorData = []
    this.selectedCategory = 'Email'
    this.emailOrReg = []
    this.errorDataToDisplay = []
  }

  handleDriveStatusError() {
    if (this.driveStatus === PatDriveStatus.ongoing || this.driveStatus === PatDriveStatus.upcoming) {
      return true;
    }

    this.messageService.add({
      severity: Severity.ERROR,
      summary: Summary.ERROR,
      detail: this.driveStatus === PatDriveStatus.completed ? Message.DRIVECOMPLETE : (this.driveStatus === PatDriveStatus.hold ? Message.DRIVEHOLD : Message.DRIVEINSAVED)
    });

    return false;
  };

  async roundAction() {
    if (!this.handleDriveStatusError()) return;

    if (!this.emailOrReg.length) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ENTEREMAILREG,
      });
    };

    const payload: any = {
      group_account_id: this.group_account_id,
      drive_id: this.drive_id,
      purpose: this.sidebarPurpose === 'addStudents' ? 'Add' : 'Remove',
      email_ids: this.selectedCategory === 'Email' ? this.emailOrReg : [],
      reg_ids: this.selectedCategory === 'Registration Number' ? this.emailOrReg : [],
      event_id: this.selectedRound.event_id,
    };

    this.isLoading = true;
    const response = await this.driveService.manuallyAddRemoveStudentsRounds(payload)
    this.isLoading = false;

    if (!response.success) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ERROROCCURED,
      });
      return;
    };

    const { error_data } = response.data;

    if (error_data?.length) {
      this.errorData = error_data;
      this.currentPageErrorTable = 1;
      this.totalPageErrorTable = Math.ceil(this.errorData.length / 25);

      this.loadCurrentPageData();
      this.scrollToTableContainer();
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.VALIDATIONERROR,
        detail: Message.CHECKERRDATA,
      });
    };

    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: Summary.SUCCESS,
      detail: `Students manually ${this.sidebarPurpose === 'addStudents' ? 'added' : 'removed'}`,
    });
    
    this.clearInputs();
    this.fetchStudentsInDriveRound();
    this.getCountOfEveryRound();
  }

  async handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData: any = event.clipboardData;
    const pastedText = clipboardData.getData('text');

    if (this.selectedCategory === 'Email') {
      const pastedEmails = pastedText.split(/[\n\s]+/).map((email: any) => email.trim().toLowerCase()).filter((email: any) => email !== '');
      this.emailOrReg.pop();
      this.validateEmailRegNo(pastedEmails);
    } 
    else {
      const pastedData = pastedText.split(/[\n\s]+/).map((data: any) => data.trim()).filter((data: any) => data !== '');
      this.emailOrReg.pop();
      this.validateEmailRegNo(pastedData);
    }
  };

  async validateEmailRegNo(emailsToAdd: string[]) {
    let validData = emailsToAdd;
    const initialLength = validData.length;

    if (this.selectedCategory === 'Email') {
      validData = emailsToAdd.filter((ele: string) => {
        return this.globalService.emailPattern.test(ele) ? ele.toLowerCase().trim() : null;
      });
    }
    else {
      validData = emailsToAdd.filter((ele: string) => {
        return this.globalService.regNoPattern.test(ele)
      });
    }

    if(validData.length !== initialLength) {
        this.messageService.add({
          severity: Severity.WARN,
          summary: Summary.VALIDATIONERROR,
          detail: `Invalid ${this.selectedCategory === 'Email' ? 'Email' : 'Registration Number'} found`,
        });
    };

    this.emailOrReg = Array.from(new Set([...this.emailOrReg, ...validData]));
  };

  async handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.validateEmailRegNo([this.emailOrReg.pop()]);
    }
  }

  async hideDownloadBar() {
    this.showDownData = false;

    this.downloadCategories.forEach((element: any) => {
      element.isSelectedAll = false;
      element.data = []
    });
    this.downloadColumnAs = 'excel';
  };

  async openDownloadDataSideBar() {
    this.showDownData = true;
    this.isLoading = true;
    const payload = {
      group_account_id: this.group_account_id,
      account_id: this.driveData.account_id,
      pass_out_year: this.driveData.passed_out_year,
    };

    let response = await this.globalService.fetchMappedFieldsBulk(payload);

    if (!response.success && !response.data) {
      this.isLoading = false;
      return this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.EDITFIELDS,
        detail: Message.FIELDMAP,
      });
    }

    const uniqueFields = new Set();

    for (const eachCampus of response.data) {
      const { academic_info, basic_info, additional_info } = eachCampus;

      basic_info.forEach((element: any) => {
        if (element.attribute_name === 'Profile Picture' || element.attribute_name === 'Email' || uniqueFields.has(element.attribute_id)) {
          return;
        }
        this.downloadCategories[0].data.push({
          label: element.attribute_label,
          value: element.attribute_name,
          active: false
        });
        uniqueFields.add(element.attribute_id);
      });


      academic_info.forEach((element: any) => {
        if (uniqueFields.has(element.attribute_id)) return;
        this.downloadCategories[1].data.push({
          label: element.attribute_label,
          value: element.attribute_name,
          active: false
        });
        uniqueFields.add(element.attribute_id);
      });

      additional_info.forEach((element: any) => {
        if (element.field_type === "Terms & Conditions" || uniqueFields.has(element.attribute_id)) return;
        this.downloadCategories[2].data.push({
          label: element.attribute_label,
          value: element.attribute_name,
          type: element.field_type,
          active: false
        });
        uniqueFields.add(element.attribute_id);
      });
    }

    this.downloadCategories[3].data.push({
      label: 'Resume',
      value: 'resume',
      type: 'resume',
      active: false
    });

    this.driveData.optionalFormData.forEach((element: any) => {
      if (element.fieldType === "termscondition") return;
      this.downloadCategories[4].data.push({
        label: element.question,
        value: element.id,
        type: element.fieldType,
        active: false
      });
    });

    const otherFields = ['Campus', 'Pass Out Year', 'Admission Year', 'Department', 'Degree And Specialization', 'Manually Added', 'Timestamp', 'Interested Profile', 'Offer Type', `Offered ${this.salary_label}`, 'Offered Stipend', 'Resume Link', 'Internship Count', 'Internship Companies', 'Internship Category', 'Internship Offer Type', `Highest ${this.salary_label} Internship`, 'Highest Stipend Internship', 'Placed Count', 'Placed Companies', 'Placed Category', 'Placed Offer Type', `Highest ${this.salary_label} Placed`, 'Highest Stipend Placed'];
    const customAcademicOrder = ['10th Mark', '12th Mark', 'Diploma Mark', 'UG Mark', 'PG Mark', 'Current Backlogs', 'Backlog History', 'Registration Number', 'Interest for Placement'];
    const customBasicOrder = ['First Name', 'Last Name', 'Gender', 'Email', 'Mobile Number', 'Profile Picture', 'Date of Birth'];

    this.downloadCategories[0].data.sort((a: any, b: any) => customBasicOrder.indexOf(a.value) - customBasicOrder.indexOf(b.value));
    this.downloadCategories[1].data.sort((a: any, b: any) => customAcademicOrder.indexOf(a.value) - customAcademicOrder.indexOf(b.value));

    otherFields.forEach((element: any) => {
      this.downloadCategories[1].data.push({
        label: element,
        value: element,
        active: false
      });
    });
    this.isLoading = false;
  }

  selectAllDownloadData(index: number) {
    this.downloadCategories[index].data.forEach((element: any) => {
      element.active = this.downloadCategories[index].isSelectedAll;
    });
  }

  onDropDownChange(index: number) {
    this.downloadCategories[index].isSelectedAll = this.downloadCategories[index].data.every((element: any) => element.active);
  }

  async downloadData() {
    if (!this.dataList.length) return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.NOSTUDENTDATA });

    const basicAndAcademicFields: any = [];

    this.downloadCategories[0].data.forEach((element: any) => {
      if (element.active) basicAndAcademicFields.push(element.value);
    });

    this.downloadCategories[1].data.forEach((element: any) => {
      if (element.active) basicAndAcademicFields.push(element.value);
    });

    const additionalFields = this.downloadCategories[2].data
      .filter((element: any) => element.active);

    const resumeFields = this.downloadCategories[3].data[0].active ? ['resume'] : [];

    const optionalFields = this.downloadCategories[4].data
      .filter((element: any) => element.active);

    if (!basicAndAcademicFields.length && !additionalFields.length && !optionalFields.length && !resumeFields.length) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.ATLEASTONEFIELD })
    }

    const selectedUsers = this.tableComponent.selectedAll ? [] : this.tableComponent.selectedOptions.map((each: any) => each.user_id);

    const payload = {
      drive_name: this.driveData.name,
      company_name: this.driveData.company_name,
      drive_id: this.driveData.drive_id,
      group_account_id: this.group_account_id,
      filters: this.getSelectedFilters(),
      limit: this.dataCount,
      page: this.selectedPage,
      search: this.searchValue,
      basicAndAcademicFields,
      additionalFields,
      optionalFields,
      downloadAs: this.downloadColumnAs,
      user_id: this.userData.user_id,
      origin: window.location.origin,
      selectedUsers,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      type: 'download',
      event_id: this.selectedRound.event_id,
      resumesDownload: !!resumeFields.length
    };

    this.hideDownloadBar();

    this.isLoading = true;
    const response = await this.driveService.fetchStudentsInDriveRound(payload);
    this.isLoading = false;

    if (response.success) {
      return this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.BACKGROUNDPROCESS
      });
    }

    return this.messageService.add({
      severity: Severity.ERROR,
      summary: Summary.ERROR,
      detail: Message.DATADWNLDERR,
    });
  }

  loadCurrentPageData() {
    const startIndex = (this.currentPageErrorTable - 1) * 25;
    const endIndex = startIndex + 25;
    this.errorDataToDisplay = this.errorData.slice(startIndex, endIndex);
  };

  previousPagination() {
    if (this.currentPageErrorTable > 1) {
      this.currentPageErrorTable--;
      this.loadCurrentPageData();
    }
  };

  nextPagination() {
    if (this.currentPageErrorTable < this.totalPageErrorTable) {
      this.currentPageErrorTable++;
      this.loadCurrentPageData();
    }
  };

  downloadErrorData() {
    const formattedData = this.errorData.map(({ data, message }) => {
      return {
        'Student Data': data,
        'Error Message': message
      }
    })

    this.globalService.downloadFile(formattedData, 'excel', `${this.driveData.name} Round Error Details`)
  };

  scrollToTableContainer() {
    this.tableContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}


