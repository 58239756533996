import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeOfferedService {

  constructor(private http: HttpService) { }

  async fetchProgrammeDetails() {
    const fetchProgrammeDetails =
      await this.http.get(
        '/programmeMapping/fetchProgramme',
      );
    return fetchProgrammeDetails;
  }

  async fetchForSuggestions(group_account_id: any) {
    const fetchForSuggestions =
      await this.http.get(
        `/programmeMapping/fetchForSuggestions/${group_account_id}`,
      );
    return fetchForSuggestions;
  }

  async fetchProgrammeMapping(body: any) {
    const fetchProgrammeMapping =
      await this.http.post(
        '/programmeMapping/fetchProgrammeMapping',
        body
      );
    return fetchProgrammeMapping;
  }

  async programmeMapping(body: any) {
    const programmeMapping =
      await this.http.post(
        '/programmeMapping/create',
        body
      );
    return programmeMapping;
  }

  async deleteProgrammeMapping(body: any) {
    const programmeMapping =
      await this.http.post(
        '/programmeMapping/delete',
        body
      );
    return programmeMapping;
  }

  async fetchAllGrouping(group_account_id: string) {
    const response = await this.http.post('/programmeMapping/fetchAllGrouping', { group_account_id });
    return response;
  }

  async mapGrouping(body: any) {
    const response = await this.http.post('/programmeMapping/mapGrouping', body);
    return response;
  };

  async removeGrouping(body: any) {
    const response = await this.http.post('/programmeMapping/removeGrouping', body);
    return response;
  }
}
