export enum Programmes{
    ADMISSIONYEAR = 'Admission Year',
    DEPARTMENT = 'Department',
    UG = 'UG',
    PG = 'PG',
    PHD = 'PhD',
    CAMPUS = 'Campus',
    PROGRAMMEMAPPING = 'Programme Mapping',
    SAVE = 'Save'
}

export const ProgrammeBtnProperties = {
    mapProgramme: {
        btnName: 'Map Programme',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    rejectBtn: {
        btnName: 'Reject',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-red-star)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    }
}