import { Component, EventEmitter, Input, Output } from '@angular/core';
import { btnProperties } from '@models';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent  {
  @Input() btnProperties: btnProperties = {
    btnName: 'Download',
  };
  @Input() btnLoading: boolean = false;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  buttonClicked() {
    this.buttonClick.emit(this.btnProperties.btnName);
  }

  dynamicStyles() {
    return {
      'color': this.btnProperties.fontColor,
      'background': this.btnProperties.background,
      'border': this.btnProperties.border,
      'width': this.btnProperties.width,
      'font-weight': this.btnProperties.fontWeight,
      'font-size': this.btnProperties.fontSize,
      'height': this.btnProperties.height,
      'border-radius': this.btnProperties.borderRadius,
      'margin-left': this.btnProperties.marginleft,
      'padding': this.btnProperties.padding,
      'cursor': this.btnProperties.cursor
    }
  }

}
