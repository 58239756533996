import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponentModule } from '@shared';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ChatComponent } from './chat.component';
import { ChatRoutingModule } from './chat-routing.module';
import { TooltipModule } from 'primeng/tooltip';
import { TabModule } from '@shared';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SidebarModule } from 'primeng/sidebar';
import { PipeModule } from '@pipes';
import { SelectButtonModule } from 'primeng/selectbutton';
@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    ChatRoutingModule,
    ProgressSpinnerModule,
    TableComponentModule,
    ToastModule,
    TooltipModule,
    TabModule,
    FormsModule,
    DividerModule,
    NgxDropzoneModule,
    OverlayPanelModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    ConfirmDialogModule,
    SidebarModule,
    PipeModule,
    SelectButtonModule
  ],
})
export class ChatModule {}
