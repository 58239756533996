import { EligibilityCriteriaRoutingModule } from './eligibility-criteria-routing.module';
import { EligibilityCriteriaComponent } from './eligibility-criteria.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { InplaceModule } from 'primeng/inplace';
import { PanelModule } from 'primeng/panel';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ChipModule } from 'primeng/chip';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule, HandleErrorModule, TableComponentModule ,TabModule } from '@shared';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { SplitterModule } from 'primeng/splitter';
import { TooltipModule } from 'primeng/tooltip';
import { ChipsModule } from 'primeng/chips';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton'
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  declarations: [
    EligibilityCriteriaComponent
  ],
  imports: [
    CommonModule,
    EligibilityCriteriaRoutingModule,
    CommonModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    OverlayPanelModule,
    DividerModule,
    InplaceModule,
    PanelModule,
    ConfirmPopupModule,
    ToastModule,
    DialogModule,
    ChipModule,
    SidebarModule,
    InputTextModule,
    ButtonModule,
    HandleErrorModule,
    MultiSelectModule,
    CheckboxModule,
    ConfirmDialogModule,
    TableComponentModule,
    PanelMenuModule,
    AccordionModule,
    InputSwitchModule,
    TabModule,
    InputNumberModule,
    SplitterModule,
    TooltipModule,
    ChipsModule,
    TableModule,
    CalendarModule,
    RadioButtonModule,
    SplitButtonModule,
    InputTextareaModule
  ],
  exports: [EligibilityCriteriaComponent]
})
export class EligibilityCriteriaModule { }
