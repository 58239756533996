import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService, HttpService } from '@services';
import { driveTemplates } from '../../../core/enums/index';

@Component({
  selector: 'app-drive-email-template',
  templateUrl: './drive-email-template.component.html',
  styleUrls: ['./drive-email-template.component.css']
})
export class DriveEmailTemplateComponent implements OnInit {
  @Input() email_templates:any = {};
  @Input() allTemplates:any = {};
  @Output() updateEmailTemplete: EventEmitter<any> = new EventEmitter();
  group_account_id: string = '';
  allEmailTemplates: any = {};
  isLoading: boolean = false;
  templateFormGroup: FormGroup = new FormGroup({});
  driveTemplate = driveTemplates;

  constructor(
        public httpService: HttpService, 
        public globalService: GlobalService,
      ) { }

  async ngOnInit() {
    let accountData = this.globalService.getLocalStorage('account_details')!;
    this.group_account_id = JSON.parse(accountData).group_account_id;

    for(let each of this.driveTemplate){ 

      let templateKey = each.value;
      this.allEmailTemplates[templateKey] = [];
      let defaultId = null;
      let selectedTemplate = null;

      this.allTemplates[templateKey]?.forEach((template: any) => {
        if(!template.active) return;

        this.allEmailTemplates[templateKey].push({
          label: template.template_title,
          value: template.id
        });

        if(template.isDefault) {
          defaultId = template.id;
        }

        if(this.email_templates[templateKey] == template.id) {
          selectedTemplate = template.id;
        }
      });

      const emailTemplateId = selectedTemplate || defaultId;
      this.templateFormGroup.addControl(templateKey, new FormControl(emailTemplateId, Validators.required));
    }

  }

  async saveEmailTemplates(drive_id: string) {
    if(this.templateFormGroup.invalid) {
      return { message: 'Please select all email templates', status: false };
    }

    this.isLoading = true;
    
    let payload = {
      drive_id,
      email_templates: this.templateFormGroup.value
    }

    let res = await this.httpService.post('/drives/saveDriveTemplates', payload);
    this.isLoading = false;
    this.updateEmailTemplete.emit(this.templateFormGroup.value);

    if(res.data) {
      return { message: 'Email templates saved successfully', status: true };
    } 

    return { message: 'Failed to save email templates', status: false };
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }
}
