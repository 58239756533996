import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriveRoundCreationComponent } from './drive-round-creation.component';

const routes: Routes = [
  {
    path: '',
    component: DriveRoundCreationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriveRoundCreationRoutingModule { }
