import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayText'
})
export class DisplayTextPipe implements PipeTransform {

  transform(value: any, combineValue = ''): string {
    if(Array.isArray(value)) {
      return value.join(', ');
    }

    if (value === 0 || value) {
      if (combineValue === 'Percentage') {
        return `${value}%`;
      } else if (value === 'NA' && combineValue === 'NA') {
        return 'NA';
      }

      return value + ' ' + (combineValue || '');
    }
    return '-';
  }
}
