export enum Students{
    STUDENTINVITE = 'Student Invite',
    SINGLEINVITE = 'Single Invite',
    CHANGEREQUEST = 'Change Request',
    CAMPUS = 'Campus',
    ADMISSIONYEAR = 'Admission Year',
    DEPARTMENT = 'Department',
    PASSEDOUTYEAR = 'Pass Out Year',
    STUDENTTYPE = 'Student Type',
    DEGREESPEC = 'Degree & Specialization',
    EMAILIDS = 'Email IDs',
    STUDENTDETAILS = 'Student Details',
    BULKUPDATE = 'Bulk Update',
    MOREFILTERS = 'More Filters',
    BATCH = 'Batch',
    SETOFFER = 'Set Offer Type',
    PLACEDSTUDENTS = 'Add Placed Students'
}

export const SampleData = {
"First Name": "user",
"Last Name":"name",
"Gender":"Male/Female",
"Mobile Number":"9876543210",
"Date of Birth":"06-Nov-1998",
"10th Mark":"95",
"12th Mark":"89",
"Diploma Mark":"8.5",
"UG Mark":"9.1",
"PG Mark":"80.5",
"Current Backlogs":"1",
"Backlog History":"Yes/No",
"Registration Number":"123ewq",
"Interest for Placement":"Yes/No",
"Preferred Work Location":"Coimbatore,Bangalore,Chennai",
"Marital status":"Single/Married",
}

export const StudentDetailsCategory = [
    { label: 'Basic Info', value: 'basicInfo', isActive: true },
    { label: 'Admission Info', value: 'admissionInfo', isActive: false },
    { label: 'Academic Information', value: 'academicInfo', isActive: false },
]

export const MessageTypeOptions = [
    { label: 'Email', value: 'email' },
    { label: 'Push Notification', value: 'pushNotification' },
    // { label: 'Whatsapp', value: 'whatsapp' }
]

export const MessageCategoryOptions = [
    { label: 'Drive', value: 'drive' },
    { label: 'Profile', value: 'profile' },
    { label: 'Other', value: 'other' }
]

export const StudentTypeOptions = [
    { label: 'Regular', value: 'Regular', isActive: true },
    { label: 'Lateral', value: 'Lateral', isActive: false },
]

export const StudentProfileCategory = [
    { label: 'Details', value: 'details', isActive: true },
    { label: 'Drives', value: 'drives', isActive: false },
    { label: 'Audit', value: 'audit', isActive: false },
]

export const driveCategory = [
    { label: 'Placement', value: 'Placement', isActive: true },
    { label: 'Internship', value: 'Internship', isActive: false },
]

export const SBtnProperties = {
    cancelStudent: {
        btnName: 'Cancel',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    saveStudent: {
        btnName: 'Save',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    downloadStudent: {
        btnName: 'Download',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    clearAll: {
        btnName: 'Clear All',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-primary-accent)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    approvedBtn: {
        btnName: 'Approve',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-primary-accent)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    rejectBtn: {
        btnName: 'Reject',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-primary-accent)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    sendEmail: {
        btnName: 'Send',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
}

export const studentActions = [
    { label: 'Approve', value: 'approve' },
    { label: 'Reject', value: 'reject' },
    // { label: 'Edit', value: 'edit' },
    // { label: 'Delete', value: 'delete' },
    // { label: 'Send Message', value: 'sendMsg' },
    { label: 'Enable Drives', value: 'enableDrives' },
    { label: 'Disable Drives', value: 'disableDrives' },
    { label: 'Enable Platform', value: 'enablePortal' },
    { label: 'Disable Platform', value: 'disablePortal' },
    { label: 'Reset Password', value: 'resetpwd' },
    { label: 'Resend Link', value: 'resendLink' }
    // { label: 'Download Resume', value: 'downloadResume' },
    // { label: 'Download Custom Field Uploaded file', value: 'downloadcustomField' }
]
