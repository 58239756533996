import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class DrivePlannerService {

    constructor(private http: HttpService) { }

    async getDriveDetails(payload: any) {
        return await this.http.post(
            '/drive-planner/getDriveDetails',
            payload
        );
    };

    async saveEvent(payload: any) {
        return await this.http.post(
            '/drive-planner/saveEvent',
            payload
        );
    };

    async updateEvent(payload: any) {
        return await this.http.post(
            '/drive-planner/updateEvent',
            payload
        );
    };

    async deleteEvent(payload: any) {
        return await this.http.post(
            '/drive-planner/deleteEvent',
            payload
        );
    }

    async getEvents(payload: any) {
        return await this.http.post(
            '/drive-planner/getEvents',
            payload
        );
    }

    async deleteDriveEvents(payload: any) {
        return await this.http.post(
            '/drive-planner/deleteDriveEvents',
            payload
        );
    }
}
