import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab.component';
import { PipeModule } from '@pipes';

@NgModule({
  declarations: [ TabComponent ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [ TabComponent ]
})
export class TabModule { }
