export type TemplateTypes = 'platformTemplates' | 'driveTemplates' | 'customTemplates';

export const platformTemplates = [
    { label: 'Bulk Invite', value: 'bulk_invite', isActive: true },
    { label: 'Bulk Upload', value: 'bulk_upload' },
    { label: 'Drive Enable', value: 'drive_status_enable' },
    { label: 'Drive Disable', value: 'drive_status_disable' },
    { label: 'Approve Student', value: 'profile_status_enable' },
    { label: 'Reject Student', value: 'profile_status_disable' },
    { label: 'Platform Enable', value: 'platform_status_enable' },
    { label: 'Platform Disable', value: 'platform_status_disable' },
    { label: 'Forgot Password', value: 'forgot_password' },
    { label: 'Email Change', value: 'email_change' },
    { label: 'Send OTP', value: 'send_otp' },
    { label: 'Email Verification', value: 'email_verification' },
];

export const driveTemplates = [
    { label: 'Drive Eligible Student', value: 'drive_eligible_student', isActive: true },
    { label: 'Drive Not Eligible Student', value: 'drive_not_eligible_student' },
    { label: 'Drive Opted-In Student', value: 'drive_opted_in_student' },
    { label: 'Manually Opted-In Student', value: 'manually_opted_in_student' },
    { label: 'Drive Opted-Out Student', value: 'drive_opted_out_student' },
    { label: 'Manually Opted-Out Student', value: 'manually_opted_out_student' },
    { label: 'Drive Waiting List', value: 'drive_waiting_list' },
    { label: 'Drive Placed Student', value: 'drive_placed_student' },
    { label: 'Manually Removed From Drives', value: 'manually_removed_from_drives' },
    { label: 'Criteria changed Not-Eligible Student', value: 'criteria_changed_not_eligible_student' },
    { label: 'Drive Date Changes', value: 'drive_date_changed' },
    { label: 'Drive On Hold Mail', value: 'drive_on_hold_mail' },
    { label: 'Optional Form Updates', value: 'drive_optional_form_updates' },
    { label: 'Offer letter notify', value: 'offer_confirmation_letter_notify' },
    { label: 'Drive Round Remainder', value: 'drive_round_remainder' },
];

export const customTemplates = [
    { label: 'Custom Templates', value: 'custom_templates', isActive: true },
];

export const CategoriesMap: Record<TemplateTypes, any> = {
    platformTemplates: platformTemplates,
    driveTemplates: driveTemplates,
    customTemplates: customTemplates,
}

export const EmailTemplateTypes = [
    { label: 'Platform Templates', value: 'platformTemplates' },
    { label: 'Drive Templates', value: 'driveTemplates' },
    { label: 'Custom Templates', value: 'customTemplates' },
];

export const CampaignTypes = {
    "Drive Eligible Student": ["Drives", 'drives-bg'],
    "Drive Not Eligible Student": ["Drives", 'drives-bg'],
    "Drive Opted In Student": ["Drives", 'drives-bg'],
    "Manually Opted In Student": ["Drives", 'drives-bg'],
    "Drive Opted Out Student": ["Drives", 'drives-bg'],
    "Manually Opted Out Student": ["Drives", 'drives-bg'],
    "Drive Waiting List": ["Drives", 'drives-bg'],
    "Drive Placed Student": ["Drives", 'drives-bg'],
    "Manually Removed From Drives": ["Drives", 'drives-bg'],
    "Criteria Changed Not Eligible Student": ["Drives", 'drives-bg'],
    "Drive Date Changed": ["Drives", 'drives-bg'],
    "Drive On Hold Mail": ["Drives", 'drives-bg'],
    "Drive Optional Form Updates": ["Drives", 'drives-bg'],
    "Custom Message": ["Custom Message", "custom-message-bg"],
    "Drive Status Active": ["Platform Generated", "platform-generated-bg"],
    "Drive Status Inactive": ["Platform Generated", "platform-generated-bg"],
    "Profile Status Approved": ["Platform Generated", "platform-generated-bg"],
    "Profile Status Rejected": ["Platform Generated", "platform-generated-bg"],
    "Platform Status Enable": ["Platform Generated", "platform-generated-bg"],
    "Platform Status Disable": ["Platform Generated", "platform-generated-bg"],
    "Forgot Password": ["Platform Generated", "platform-generated-bg"],
    "Email Change": ["Platform Generated", "platform-generated-bg"],
    "Send OTP": ["Platform Generated", "platform-generated-bg"],
    "Email Verification": ["Platform Generated", "platform-generated-bg"],
    "Bulk Invite": ["Student Invited", "student-invited-bg"],
    "Bulk Upload": ["Student Invited", "student-invited-bg"],
    "Drive Round Remainder": ["Drives", 'drives-bg'],
};