import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class DateRangePickerComponent implements OnInit {
  @Output() dateRangeSelected = new EventEmitter<{ fromDate: Date, toDate: Date }>();
  @Input() fromDate: any;
  @Input() toDate: any;
  showError: boolean = false;
  
  dateRangeForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.dateRangeForm = this.fb.group({
      fromDate: [null],
      toDate: [null]
    });
  }

  ngOnInit() {
    if(this.fromDate && this.toDate)
    {
      this.dateRangeForm.get('fromDate')?.setValue(this.fromDate);
      this.dateRangeForm.get('toDate')?.setValue(this.toDate);
    }
  }

  onDateSelect(event: any) {
    // event.preventDefault();
    let { fromDate, toDate } = this.dateRangeForm.value;
    if (fromDate && toDate) {
      this.showError = false;
    }
  }

  setToday() {
    const today = new Date();
    this.dateRangeForm.patchValue({
      fromDate: today,
      toDate: today
    });
  }

  setThisWeek() {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    this.dateRangeForm.patchValue({
      fromDate: firstDayOfWeek,
      toDate: lastDayOfWeek
    });
  }

  setThisMonth() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.dateRangeForm.patchValue({
      fromDate: firstDayOfMonth,
      toDate: lastDayOfMonth
    });
  }

  setThisYear() {
    const currentYear = new Date().getFullYear();
    this.dateRangeForm.patchValue({
      fromDate: new Date(currentYear, 0, 1),
      toDate: new Date(currentYear, 11, 31)
    });
  }

  setLastDay() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.dateRangeForm.patchValue({
      fromDate: yesterday,
      toDate: today
    });
  }

  setLast7Days() {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    this.dateRangeForm.patchValue({
      fromDate: last7Days,
      toDate: today
    });
  }

  setLastMonth() {
    const today = new Date();
    const lastMonthStart = new Date(today);
    lastMonthStart.setMonth(today.getMonth() - 1);
    lastMonthStart.setDate(1);

    const lastMonthEnd = new Date(today);
    lastMonthEnd.setDate(0);

    this.dateRangeForm.patchValue({
      fromDate: lastMonthStart,
      toDate: lastMonthEnd
    });
  }

  setLast3Months() {
    const today = new Date();
    const last3MonthsStart = new Date(today);
    last3MonthsStart.setMonth(today.getMonth() - 3);
    last3MonthsStart.setDate(1);

    this.dateRangeForm.patchValue({
      fromDate: last3MonthsStart,
      toDate: today
    });
  }

  applyDateRange() {
    let { fromDate, toDate } = this.dateRangeForm.value;
    if (fromDate && toDate) {
      this.showError = false;
      toDate = new Date(toDate);
      toDate.setHours(23, 59, 59, 999);
      this.dateRangeSelected.emit({ fromDate, toDate });
    }
    else{
      this.showError = true;
    }
  }

  clearDateRange() {
    this.dateRangeForm.patchValue({
      fromDate: null,
      toDate: null
    });
    this.showError = false;
  }

  formatDate(date: Date): string {
    if (!date) return '';
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }
}
