import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DriveEmailTemplateRoutingModule } from './drive-email-template-routing.module';
import { DriveEmailTemplateComponent } from './drive-email-template.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { PipeModule } from '@pipes';

@NgModule({
  declarations: [
    DriveEmailTemplateComponent
  ],
  imports: [
    PipeModule,
    FormsModule,
    CommonModule,
    DriveEmailTemplateRoutingModule,
    ProgressSpinnerModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule
  ],
  exports: [DriveEmailTemplateComponent]
})
export class DriveEmailTemplateModule { }
