import { Component, ElementRef, ViewChild } from '@angular/core';
import { MessageInsightsService } from 'src/app/core/services/message-insights.service';
import { emailBoxIcon, pushMessageIcon } from '@assets';
import { GlobalService } from 'src/app/core/services/global.service';
import { MessageService } from 'primeng/api';
import { CampaignTypes } from '@enum';

interface IFilterData {
  filterName: string;
  key: string;
  array: any[];
}
@Component({
  selector: 'message-insights',
  templateUrl: './message-insights.component.html',
  styleUrls: ['./message-insights.component.css']
})

export class MessageInsightsComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  searchValue: any = '';
  searchColor: boolean = true;
  accountData: any = localStorage.getItem('account_details')
  userData: any = localStorage.getItem('user_details');
  pushMessageIcon: any = pushMessageIcon;
  emailBoxIcon: any = emailBoxIcon;
  campaignData: any[] = [];
  selectedCampaign: any;
  rangeDates: Date[] | undefined;
  group_account_id: any;
  selectedFilters: any = {
    sentBy: '',
    sentOn: [],
    campaignType: ''
  };
  selectedCampaignIndex: number = -1; 
  isLoading: boolean = true;
  filterData: IFilterData[] = [
    {
      filterName: "Campaign Type",
      key: 'campaignType',
      array: [
        {
          label: "Drives", value: "drives"
        },
        {
          label: "Custom Messages", value: "custom_messages"
        },
        {
          label: "Platform Generated", value: "platform_generated"
        },
        {
          label: "Student Invited", value: "student_invited"
        }
      ]
    },
    {
      filterName: "Sent By",
      key: 'sentBy',
      array: []
    },
    {
      filterName: "Sent On",
      key: 'sentOn',
      array: []
    }
  ];
  messageTypes = [
    { label: 'Drives', colorCode: 'var(--clr-violet)'},
    { label: 'Custom Messages', colorCode: 'var(--clr-orange-text)' },
    { label: 'Platform Generated', colorCode: 'var(--clr-blue)' },
    { label: 'Student Invited', colorCode: 'var(--clr-green)' },
  ];
  page: number = 1;
  limit: number = 10;
  allDataLoaded: boolean = false;
  campaignTypes: any = CampaignTypes;
  showEmailDialog: boolean = false;
  emailsToDisplay: string[] = [];
  emailDialogTitle: string = '';

  constructor(
      private messageInsightsService: MessageInsightsService, 
      public globalService : GlobalService,
      private messageService: MessageService
  ) {
      this.accountData = JSON.parse(this.accountData);
      this.userData = JSON.parse(this.userData);
      this.group_account_id = this.accountData.group_account_id;
  }
  templateBody : any;
  subjectTitle: any;
  senderEmail: any;
  isScrolling: boolean = false;

  async ngOnInit() {
    this.isLoading = true;
    await this.fetchCampaignDetails();
    await this.applyFirstCampaign();
    this.isLoading = false;
  };

  async fetchCampaignDetails() {
    if (this.isScrolling || this.allDataLoaded) {
      if(this.campaignData.length == 0) this.selectedCampaign = null;
      return;
    };

    this.isScrolling = true;
    const { success, data } = await this.messageInsightsService.getAllCampaign({
      group_account_id: this.accountData.group_account_id,
      filters: this.selectedFilters,
      limit: this.limit,
      page: this.page,
      search: this.searchValue
    });
    this.isScrolling = false;

    if(!success) {
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error fetching data. Please try again.'
      });
    };

    if (data.length === 0) {
      this.allDataLoaded = true;
    };
    this.campaignData = [...this.campaignData, ...data];

    this.page += 1;
    this.filterData[1].array = Array.from(new Set(this.campaignData.map((campaign: any) => campaign.sender_email)));;

    console.log('campaignData:', this.campaignData.length);

    if(this.campaignData.length == 0) {
      this.selectedCampaign = null;
    }
  };

  async onScroll() {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const containerHeight = this.scrollContainer.nativeElement.clientHeight + 10;

    if (scrollTop + containerHeight >= scrollHeight) {
      await this.fetchCampaignDetails();
      console.log('fetching more data');
    }
  };

  async applyFirstCampaign() {
    if (this.campaignData.length > 0) {
      await this.getSpecificCampaign(this.campaignData[0], 0);
    }
  }

  async getSpecificCampaign(selectedCampaignDetails: any, index: number) {
    if(this.selectedCampaignIndex === index) return;

    this.selectedCampaignIndex = index;

    if (!this.accountData.workspace_id) {
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Account not set up with messages insights. Contact support for help.'
      });
    }

    try {
      this.isLoading = true;

      const ids: string[] = [];
      const formattedInsights: string[] = [];

      selectedCampaignDetails.message_insights.forEach((each: any) => {
        each = JSON.parse(each);
        console.log('each:', each);
        ids.push(each.id);
        formattedInsights.push(each.to_email.split('@')[0]);
      });

      const payload = {
        correlationIds: ids,
        workspace_id: this.accountData.workspace_id
      };

      const { data, success } = await this.messageInsightsService.getCampaignStatus(payload);
      if(!success) {
        this.isLoading = false;
        this.selectedCampaign = null;
        return;
      }

      selectedCampaignDetails.toAddresses = formattedInsights;
      selectedCampaignDetails.combinedAddress = formattedInsights.join(', ');

      this.selectedCampaign = { ...selectedCampaignDetails, ...data[0] };

      const { template_content, subject_title } = JSON.parse(JSON.stringify(this.selectedCampaign.template_body));

      // Set campaign properties
      this.templateBody = template_content;
      this.subjectTitle = subject_title;
      this.senderEmail = this.selectedCampaign.sender_email;
      this.isLoading = false;
    } catch (error) {
      console.error('Error fetching JSON data:', error);
    }
  };


  async applyFilters() {
    this.clearDateFilters();
    this.isLoading = true;
    await this.fetchCampaignDetails();
    await this.applyFirstCampaign();
    this.isLoading = false;
  }

  isDateInRange(dateString: string): boolean {
    const date = new Date(dateString);
    if (this.selectedFilters.sentOn.length === 2) {
      const [startDate, endDate] = this.selectedFilters.sentOn;
      return date >= startDate && date <= endDate;
    }
    return true;
  }

  clearDateFilters() {
    this.allDataLoaded = false;
    this.page = 1;
    this.campaignData = [];
    this.selectedCampaign = null;
    this.selectedCampaignIndex = -1;
  }

  async clearFilters() {
    this.selectedFilters = {
      sentBy: '',
      sentOn: [],
      campaignType: ''
    };
    this.searchValue = '';
    this.clearDateFilters();
    this.isLoading = true;
    await this.fetchCampaignDetails();
    await this.applyFirstCampaign();
    this.isLoading = false;
  }

  onSearchChange(event: any) {
    let searchValue = event.target.value
    this.searchColor = searchValue.length > 0;
  }

  async searchValueChange(event: any) {
    this.searchValue = event.target.value;
    this.clearDateFilters();
    this.isLoading = true;
    await this.fetchCampaignDetails();
    await this.applyFirstCampaign();
    this.isLoading = false;
  }

  async downloadFiles(files: string[], fileName: string) {

    const payload = {
      files,
      zipFileName: fileName,
      group_account_id: this.group_account_id,
      user_id: this.userData.user_id
    }

    this.isLoading = true;
    const response = await this.messageInsightsService.downloadAttachment(payload);
    this.isLoading = false;

    if(response.success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Files will downloaded in the background.'
      });
    }
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error downloading files. Please try again.'
      });
    }
  };

  openDialog(title: string, emails: string[]) {
    if(!emails?.length) return 

    this.emailsToDisplay = emails;
    this.emailDialogTitle = title + ' List';
    this.showEmailDialog = true;
  };
}
