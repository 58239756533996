import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractFilename'
})
export class ExtractFilenamePipe implements PipeTransform {

  transform(value: any, maxLength = 20) {
    if(typeof value != 'string') return value;
    const filename = value.split('/').pop() ?? '';

    if (filename.length > maxLength) {
        return filename.substring(0, maxLength) + '...';
    }

    return filename;
  };

}
