import { Injectable } from '@angular/core';
import { Subject, debounceTime, Observable, throwError, from } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { GlobalService } from '@services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private backendDownSubject = new Subject<void>();
  private backendDownDebounceTime = 5000;

  constructor(private globalService: GlobalService) {
    this.backendDownSubject.pipe(debounceTime(this.backendDownDebounceTime)).subscribe(() => {
      this.globalService.tokenValidationMessage({ message: 'Server Down' });
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err.status === 401) {
          // Handle 401 error - Unauthorized
          return from(this.globalService.refreshToken()).pipe(
            switchMap((token: string | null) => {
              // If token is available, add it to the request headers and retry the request
              if (token) {
                const newAuthReq = request.clone({
                  setHeaders: {
                    Authorization: token
                  }
                });
                return next.handle(newAuthReq);
              } else {
                // Handle session expired
                const userData = this.globalService.getLocalStorage('user_details');
                if (userData) {
                  this.globalService.tokenValidationMessage({ message: 'Session Expired' });
                }
                this.globalService.resetLogin();
                return throwError(() => new Error('Session expired, please log in again.'));
              }
            })
          );
        } else if (err.status === 0 || err.status === 503) {
          // Handle backend down scenarios
          this.backendDownSubject.next();
        }
        return throwError(() => err);
      })
    );
  }

}
