
export const AuditOptions = [
    { label: 'All', value: 'all' },
    { label: 'Profile Approve', value: 'Profile Approve' },
    { label: 'Profile Reject', value: 'Profile Reject' },
    { label: 'Enable from Drives', value: 'Enable Drive' },
    { label: 'Disable from Drives', value: 'Disable Drive' },
    { label: 'Enable from portal', value: 'Enable Platform' },
    { label: 'Disable from portal', value: 'Disable Platform' },
    { label: 'Opt-In & Opt-Out', value: 'Opt In/Out' },
    { label: 'Edit Profile', value: 'Edit Profile' },
];