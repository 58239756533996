import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from '@shared';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabModule } from '@shared';
import { ChatModule } from '../module/components/chat/chat.module';
@NgModule({
  declarations: [HeaderComponent, SidebarComponent],
  imports: [
    CommonModule,
    ButtonModule,
    SplitButtonModule,
    NgDynamicBreadcrumbModule,
    BrowserAnimationsModule,
    DialogModule,
    TooltipModule,
    SidebarModule,
    DividerModule,
    OverlayPanelModule,
    InputSwitchModule,
    FormsModule,
    ProgressSpinnerModule,
    ToastModule,
    ReactiveFormsModule,
    TabModule,
    ChatModule
  ],
  exports: [HeaderComponent, SidebarComponent]
})
export class CoreModule { }
