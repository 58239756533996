import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriveRoundCreationRoutingModule } from './drive-round-creation.routing.module';
import { DriveRoundCreationComponent } from './drive-round-creation.component';
import { TableComponentModule, ButtonModule } from '@shared';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { PipeModule } from '@pipes';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [
    DriveRoundCreationComponent,
  ],
  imports: [
    CommonModule,
    DriveRoundCreationRoutingModule,
    ButtonModule,
    MultiSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    TableComponentModule,
    SidebarModule,
    DividerModule,
    CalendarModule,
    DropdownModule,
    CheckboxModule,
    RadioButtonModule,
    PipeModule,
    ChipsModule,
    ToastModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    TableComponentModule,
    TableModule,
    ButtonModule
  ],
  exports: [DriveRoundCreationComponent]
})
export class DriveRoundCreationModule { }
